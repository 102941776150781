/* Sidebar styles */
.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: -250px;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
  z-index: 1000;
}

.sidebar.open {
  left: 0;
}

.sidebar .close-btn {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 8px 8px 8px 32px;
  text-align: left;
}

.sidebar ul li a {
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar ul li a:hover,
.sidebar ul li a.active {
  color: #f1f1f1;
}

.open-btn {
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1100;
}

/* Your other custom styles */

/* Example styles */
body {
  font-family: "Arial", sans-serif;
}

.view {
  height: 100vh;
  background: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full%20page/img%20(11).jpg")
    no-repeat center center;
  background-size: cover;
}

.section {
  padding: 60px 0;
}

.fixed-bg {
  background: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full%20page/img%20(11).jpg")
    no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  height: 300px;
}

.activelist {
  color: black;
}

/* .overlap{
    display: flex;
    flex-direction: row;
  }
  .main_bar{
    left:0;
    position: relative;
    width: calc(100%-250px) !important;
  } */
/* .left-product {
    width: 200px;
    overflow-y: scroll;
    position: fixed;
    top: 50px;
} */
/* .top-header {
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    z-index: 1000 !important;
    position: fixed;
    background: #fff;
    top: 0px;
} */
