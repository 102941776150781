@font-face {
  font-family: "f1";
  src: url("../../fonts/Figtree/Figtree-Regular.ttf");
}
@font-face {
  font-family: "f2";
  src: url("../../fonts/Figtree/Figtree-Medium.ttf");
}

@font-face {
  font-family: "f3";
  src: url("../../fonts/Figtree/Figtree-SemiBold.ttf");
}

@font-face {
  font-family: "f4";
  src: url("../../fonts/Figtree/Figtree-Bold.ttf");
}

@font-face {
  font-family: "f5";
  src: url("../../fonts/Figtree/Figtree-ExtraBold.ttf");
}

@font-face {
  font-family: "f6";
  src: url("../../fonts/Figtree/P22MackinacPro-Bold.otf");
}
.max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.max-line-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
//font-size-start

.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-27 {
  font-size: 27px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-29 {
  font-size: 29px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-31 {
  font-size: 31px !important;
}
.fs-32 {
  font-size: 32px !important;
}
.fs-33 {
  font-size: 33px !important;
}
.fs-34 {
  font-size: 34px !important;
}
.fs-35 {
  font-size: 35px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-45 {
  font-size: 45px !important;
}
.fs-50 {
  font-size: 50px !important;
}
//xs
@media (max-width: 576px) {
  .fs-xs-9 {
    font-size: 9px !important;
  }
  .fs-xs-10 {
    font-size: 10px !important;
  }
  .fs-xs-11 {
    font-size: 11px !important;
  }
  .fs-xs-12 {
    font-size: 12px !important;
  }
  .fs-xs-13 {
    font-size: 13px !important;
  }
  .fs-xs-14 {
    font-size: 14px !important;
  }
  .fs-xs-15 {
    font-size: 15px !important;
  }

  .fs-xs-16 {
    font-size: 16px !important;
  }
  .fs-xs-17 {
    font-size: 17px !important;
  }
  .fs-xs-18 {
    font-size: 18px !important;
  }
  .fs-xs-19 {
    font-size: 19px !important;
  }
  .fs-xs-20 {
    font-size: 20px !important;
  }
  .fs-xs-21 {
    font-size: 21px !important;
  }

  .fs-xs-22 {
    font-size: 22px !important;
  }

  .fs-xs-23 {
    font-size: 23px !important;
  }

  .fs-xs-24 {
    font-size: 24px !important;
  }

  .fs-xs-25 {
    font-size: 25px !important;
  }
  .fs-xs-26 {
    font-size: 26px !important;
  }
  .fs-xs-27 {
    font-size: 27px !important;
  }
  .fs-xs-28 {
    font-size: 28px !important;
  }
  .fs-xs-29 {
    font-size: 29px !important;
  }
  .fs-xs-30 {
    font-size: 30px !important;
  }
  .fs-xs-31 {
    font-size: 31px !important;
  }
  .fs-xs-32 {
    font-size: 32px !important;
  }
  .fs-xs-33 {
    font-size: 33px !important;
  }
  .fs-xs-34 {
    font-size: 34px !important;
  }
  .fs-xs-35 {
    font-size: 35px !important;
  }
  .fs-xs-40 {
    font-size: 40px !important;
  }
  .fs-xs-45 {
    font-size: 45px !important;
  }
  .fs-xs-50 {
    font-size: 50px !important;
  }
  .max-line-xs-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .max-line-xs-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .max-line-xs-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .max-line-xs-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .max-line-xs-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
//sm
@media (min-width: 577px) {
  .fs-sm-9 {
    font-size: 9px !important;
  }
  .fs-sm-10 {
    font-size: 10px !important;
  }
  .fs-sm-11 {
    font-size: 11px !important;
  }
  .fs-sm-12 {
    font-size: 12px !important;
  }
  .fs-sm-13 {
    font-size: 13px !important;
  }
  .fs-sm-14 {
    font-size: 14px !important;
  }
  .fs-sm-15 {
    font-size: 15px !important;
  }

  .fs-sm-16 {
    font-size: 16px !important;
  }
  .fs-sm-17 {
    font-size: 17px !important;
  }
  .fs-sm-18 {
    font-size: 18px !important;
  }
  .fs-sm-19 {
    font-size: 19px !important;
  }
  .fs-sm-20 {
    font-size: 20px !important;
  }
  .fs-sm-21 {
    font-size: 21px !important;
  }

  .fs-sm-22 {
    font-size: 22px !important;
  }

  .fs-sm-23 {
    font-size: 23px !important;
  }

  .fs-sm-24 {
    font-size: 24px !important;
  }

  .fs-sm-25 {
    font-size: 25px !important;
  }
  .fs-sm-26 {
    font-size: 26px !important;
  }
  .fs-sm-27 {
    font-size: 27px !important;
  }
  .fs-sm-28 {
    font-size: 28px !important;
  }
  .fs-sm-29 {
    font-size: 29px !important;
  }
  .fs-sm-30 {
    font-size: 30px !important;
  }
  .fs-sm-31 {
    font-size: 31px !important;
  }
  .fs-sm-32 {
    font-size: 32px !important;
  }
  .fs-sm-33 {
    font-size: 33px !important;
  }
  .fs-sm-34 {
    font-size: 34px !important;
  }
  .fs-sm-35 {
    font-size: 35px !important;
  }
  .fs-sm-40 {
    font-size: 40px !important;
  }
  .fs-sm-45 {
    font-size: 45px !important;
  }
  .fs-sm-50 {
    font-size: 50px !important;
  }
  .max-line-sm-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .max-line-sm-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .max-line-sm-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .max-line-sm-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .max-line-sm-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
//md
@media (min-width: 768px) {
  .fs-md-9 {
    font-size: 9px !important;
  }
  .fs-md-10 {
    font-size: 10px !important;
  }
  .fs-md-11 {
    font-size: 11px !important;
  }
  .fs-md-12 {
    font-size: 12px !important;
  }
  .fs-md-13 {
    font-size: 13px !important;
  }
  .fs-md-14 {
    font-size: 14px !important;
  }
  .fs-md-15 {
    font-size: 15px !important;
  }

  .fs-md-16 {
    font-size: 16px !important;
  }
  .fs-md-17 {
    font-size: 17px !important;
  }
  .fs-md-18 {
    font-size: 18px !important;
  }
  .fs-md-19 {
    font-size: 19px !important;
  }
  .fs-md-20 {
    font-size: 20px !important;
  }
  .fs-md-21 {
    font-size: 21px !important;
  }

  .fs-md-22 {
    font-size: 22px !important;
  }

  .fs-md-23 {
    font-size: 23px !important;
  }

  .fs-md-24 {
    font-size: 24px !important;
  }

  .fs-md-25 {
    font-size: 25px !important;
  }
  .fs-md-26 {
    font-size: 26px !important;
  }
  .fs-md-27 {
    font-size: 27px !important;
  }
  .fs-md-28 {
    font-size: 28px !important;
  }
  .fs-md-29 {
    font-size: 29px !important;
  }
  .fs-md-30 {
    font-size: 30px !important;
  }
  .fs-md-31 {
    font-size: 31px !important;
  }
  .fs-md-32 {
    font-size: 32px !important;
  }
  .fs-md-33 {
    font-size: 33px !important;
  }
  .fs-md-34 {
    font-size: 34px !important;
  }
  .fs-md-35 {
    font-size: 35px !important;
  }
  .fs-md-40 {
    font-size: 40px !important;
  }
  .fs-md-45 {
    font-size: 45px !important;
  }
  .fs-md-50 {
    font-size: 50px !important;
  }
  .max-line-md-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .max-line-md-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .max-line-md-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .max-line-md-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .max-line-md-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
//lg
@media (min-width: 992px) {
  .fs-lg-9 {
    font-size: 9px !important;
  }
  .fs-lg-10 {
    font-size: 10px !important;
  }
  .fs-lg-11 {
    font-size: 11px !important;
  }
  .fs-lg-12 {
    font-size: 12px !important;
  }
  .fs-lg-13 {
    font-size: 13px !important;
  }
  .fs-lg-14 {
    font-size: 14px !important;
  }
  .fs-lg-15 {
    font-size: 15px !important;
  }

  .fs-lg-16 {
    font-size: 16px !important;
  }
  .fs-lg-17 {
    font-size: 17px !important;
  }
  .fs-lg-18 {
    font-size: 18px !important;
  }
  .fs-lg-19 {
    font-size: 19px !important;
  }
  .fs-lg-20 {
    font-size: 20px !important;
  }
  .fs-lg-21 {
    font-size: 21px !important;
  }

  .fs-lg-22 {
    font-size: 22px !important;
  }

  .fs-lg-23 {
    font-size: 23px !important;
  }

  .fs-lg-24 {
    font-size: 24px !important;
  }

  .fs-lg-25 {
    font-size: 25px !important;
  }
  .fs-lg-26 {
    font-size: 26px !important;
  }
  .fs-lg-27 {
    font-size: 27px !important;
  }
  .fs-lg-28 {
    font-size: 28px !important;
  }
  .fs-lg-29 {
    font-size: 29px !important;
  }
  .fs-lg-30 {
    font-size: 30px !important;
  }
  .fs-lg-31 {
    font-size: 31px !important;
  }
  .fs-lg-32 {
    font-size: 32px !important;
  }
  .fs-lg-33 {
    font-size: 33px !important;
  }
  .fs-lg-34 {
    font-size: 34px !important;
  }
  .fs-lg-35 {
    font-size: 35px !important;
  }
  .fs-lg-40 {
    font-size: 40px !important;
  }
  .fs-lg-45 {
    font-size: 45px !important;
  }
  .fs-lg-50 {
    font-size: 50px !important;
  }
  .max-line-lg-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .max-line-lg-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .max-line-lg-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .max-line-lg-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .max-line-lg-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
//xl
@media (min-width: 1200px) {
  .fs-xl-1 {
    font-size: 1px !important;
  }
  .fs-xl-2 {
    font-size: 2px !important;
  }
  .fs-xl-3 {
    font-size: 3px !important;
  }
  .fs-xl-4 {
    font-size: 4px !important;
  }
  .fs-xl-5 {
    font-size: 5px !important;
  }
  .fs-xl-6 {
    font-size: 6px !important;
  }
  .fs-xl-7 {
    font-size: 7px !important;
  }
  .fs-xl-8 {
    font-size: 8px !important;
  }
  .fs-xl-9 {
    font-size: 9px !important;
  }
  .fs-xl-10 {
    font-size: 10px !important;
  }
  .fs-xl-11 {
    font-size: 11px !important;
  }
  .fs-xl-12 {
    font-size: 12px !important;
  }
  .fs-xl-13 {
    font-size: 13px !important;
  }
  .fs-xl-14 {
    font-size: 14px !important;
  }
  .fs-xl-15 {
    font-size: 15px !important;
  }

  .fs-xl-16 {
    font-size: 16px !important;
  }
  .fs-xl-17 {
    font-size: 17px !important;
  }
  .fs-xl-18 {
    font-size: 18px !important;
  }
  .fs-xl-19 {
    font-size: 19px !important;
  }
  .fs-xl-20 {
    font-size: 20px !important;
  }
  .fs-xl-21 {
    font-size: 21px !important;
  }

  .fs-xl-22 {
    font-size: 22px !important;
  }

  .fs-xl-23 {
    font-size: 23px !important;
  }

  .fs-xl-24 {
    font-size: 24px !important;
  }

  .fs-xl-25 {
    font-size: 25px !important;
  }
  .fs-xl-26 {
    font-size: 26px !important;
  }
  .fs-xl-27 {
    font-size: 27px !important;
  }
  .fs-xl-28 {
    font-size: 28px !important;
  }
  .fs-xl-29 {
    font-size: 29px !important;
  }
  .fs-xl-30 {
    font-size: 30px !important;
  }
  .fs-xl-31 {
    font-size: 31px !important;
  }
  .fs-xl-32 {
    font-size: 32px !important;
  }
  .fs-xl-33 {
    font-size: 33px !important;
  }
  .fs-xl-34 {
    font-size: 34px !important;
  }
  .fs-xl-35 {
    font-size: 35px !important;
  }
  .fs-xl-36 {
    font-size: 36px !important;
  }
  .fs-xl-37 {
    font-size: 37px !important;
  }
  .fs-xl-38 {
    font-size: 38px !important;
  }
  .fs-xl-39 {
    font-size: 39px !important;
  }
  .fs-xl-40 {
    font-size: 40px !important;
  }
  .fs-xl-41 {
    font-size: 41px !important;
  }
  .fs-xl-42 {
    font-size: 42px !important;
  }
  .fs-xl-43 {
    font-size: 43px !important;
  }
  .fs-xl-44 {
    font-size: 44px !important;
  }
  .fs-xl-45 {
    font-size: 45px !important;
  }
  .fs-xl-50 {
    font-size: 50px !important;
  }
  .max-line-xl-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .max-line-xl-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .max-line-xl-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .max-line-xl-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .max-line-xl-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
//xxl
@media (min-width: 1400px) {
  .fs-xxl-1 {
    font-size: 1px !important;
  }
  .fs-xxl-2 {
    font-size: 2px !important;
  }
  .fs-xxl-3 {
    font-size: 3px !important;
  }
  .fs-xxl-4 {
    font-size: 4px !important;
  }
  .fs-xxl-5 {
    font-size: 5px !important;
  }
  .fs-xxl-6 {
    font-size: 6px !important;
  }
  .fs-xxl-7 {
    font-size: 7px !important;
  }
  .fs-xxl-8 {
    font-size: 8px !important;
  }
  .fs-xxl-9 {
    font-size: 9px !important;
  }
  .fs-xxl-10 {
    font-size: 10px !important;
  }
  .fs-xxl-11 {
    font-size: 11px !important;
  }
  .fs-xxl-12 {
    font-size: 12px !important;
  }
  .fs-xxl-13 {
    font-size: 13px !important;
  }
  .fs-xxl-14 {
    font-size: 14px !important;
  }
  .fs-xxl-15 {
    font-size: 15px !important;
  }

  .fs-xxl-16 {
    font-size: 16px !important;
  }
  .fs-xxl-17 {
    font-size: 17px !important;
  }
  .fs-xxl-18 {
    font-size: 18px !important;
  }
  .fs-xxl-19 {
    font-size: 19px !important;
  }
  .fs-xxl-20 {
    font-size: 20px !important;
  }
  .fs-xxl-21 {
    font-size: 21px !important;
  }

  .fs-xxl-22 {
    font-size: 22px !important;
  }

  .fs-xxl-23 {
    font-size: 23px !important;
  }

  .fs-xxl-24 {
    font-size: 24px !important;
  }

  .fs-xxl-25 {
    font-size: 25px !important;
  }
  .fs-xxl-26 {
    font-size: 26px !important;
  }
  .fs-xxl-27 {
    font-size: 27px !important;
  }
  .fs-xxl-28 {
    font-size: 28px !important;
  }
  .fs-xxl-29 {
    font-size: 29px !important;
  }
  .fs-xxl-30 {
    font-size: 30px !important;
  }
  .fs-xxl-31 {
    font-size: 31px !important;
  }
  .fs-xxl-32 {
    font-size: 32px !important;
  }
  .fs-xxl-33 {
    font-size: 33px !important;
  }
  .fs-xxl-34 {
    font-size: 34px !important;
  }
  .fs-xxl-35 {
    font-size: 35px !important;
  }
  .fs-xxl-36 {
    font-size: 36px !important;
  }
  .fs-xxl-37 {
    font-size: 37px !important;
  }
  .fs-xxl-38 {
    font-size: 38px !important;
  }
  .fs-xxl-39 {
    font-size: 39px !important;
  }
  .fs-xxl-40 {
    font-size: 40px !important;
  }
  .fs-xxl-41 {
    font-size: 41px !important;
  }
  .fs-xxl-42 {
    font-size: 42px !important;
  }
  .fs-xxl-43 {
    font-size: 43px !important;
  }
  .fs-xxl-44 {
    font-size: 44px !important;
  }
  .fs-xxl-45 {
    font-size: 45px !important;
  }
  .fs-xxl-46 {
    font-size: 46px !important;
  }
  .fs-xxl-47 {
    font-size: 47px !important;
  }
  .fs-xxl-48 {
    font-size: 48px !important;
  }
  .fs-xxl-49 {
    font-size: 49px !important;
  }
  .fs-xxl-50 {
    font-size: 50px !important;
  }
  .max-line-xxl-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .max-line-xxl-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .max-line-xxl-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .max-line-xxl-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .max-line-xxl-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

//font-size-end
//
//
//
//font-family-start
body {
  font-family: "f1";
}
.f1 {
  font-family: "f1";
}
.f2 {
  font-family: "f2";
}

.f3 {
  font-family: "f3";
}

.f4 {
  font-family: "f4";
}

.f5 {
  font-family: "f5";
}

.f6 {
  font-family: "f6";
}

//font-family-end
//
//
//
