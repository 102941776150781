@import "../constatnt/fonts.scss";
@import "../constatnt/color.scss";
//
//
//
//max-576px-end
@media (min-width: 320px) and (max-width: 376px) {
  .rit-auth .form-box {
    width: 290px !important;
  }

  .col-sm-4 {
    width: 50% !important;
  }

  .col-sm-8 {
    width: 80% !important;
  }

  .wid-sm-100 {
    width: 100% !important;
  }

  .modal-popup .center-content .left-popup .imges {
    margin-top: 20px !important;
    width: 290px !important;
    height: 290px !important;
  }

  .carousal-img {
    width: 250px;
    height: 250px;
  }

  .prod-cart {
    width: 250px !important;
    height: calc(100vh - 90px) !important;
    position: fixed;
    top: 85px !important;
  }

  .down_imgs {
    width: 70px !important;
    height: 70px !important;

    img {
      width: 50px !important;
      height: 50px !important;
    }
  }

  .mob-carous-pop {
    width: 200px !important;
  }

  .rit-pop {
    margin-top: 45px !important;
  }

  .prod-list-box-tl .prod-list-box {
    padding: 5px;
  }
}

@media (max-width: 577px) {
  .filter-options {
    left: 0 !important;
  }

  .alert-popup .customPopupDesign {
    width: 275px !important;
  }

  .faq-banner {
    height: 300px;
  }

  .inputcontainer2 {
    width: 80% !important;
  }

  .listbtn {
    width: 80% !important;
    margin-top: 30px;
  }

  .botton_btn {
    position: relative !important;
  }

  .btncontadd {
    height: 30px;
    width: 70px;
  }

  .place-holde2 {
    ::placeholder {
      font-size: 12px !important;
    }
  }

  .mb-res-5 {
    margin-bottom: 12px !important;
  }

  .responcive-size-w {
    width: 90% !important;
    align-self: center !important;
  }

  .custom-palce {
    ::placeholder {
      font-size: 10px !important;
    }
  }

  .prod-owl-img {
    height: 120px !important;
  }

  .w-s {
    width: 90% !important;
    justify-content: space-between !important;
    margin-bottom: 20px !important;
    align-items: center !important;
    margin-top: 20px !important;
  }

  .question-ans .faq-img {
    width: 200px;
  }

  .prod-list-box-tl {
    width: calc(50% - 2px);
    margin: 0;
    display: block;
  }

  .improsive-feature {
    flex-direction: column-reverse;
  }

  .contain {
    width: 97%;
  }

  .tl-pack {
    padding-bottom: 80px;
  }

  .home-tab {
    .pac-box {
      width: 90%;
      margin-left: 5%;

      .pac-box1 {
        h5 {
          font-size: 14px;
        }
      }
    }

    .top-img {
      .img1 {
        width: 45% !important;
      }
    }
  }

  .home-feed-back {
    .contain {
      width: 87% !important;

      .rit-box {
        width: 100% !important;
        margin-top: 10px;
        text-align: center;
      }
    }

    .box {
      .box1 {
        height: 100% !important;
      }
    }
  }

  .tab-pack {
    width: 360px;
  }

  .rit-send-merch {
    width: 330px !important;
    margin: 10px auto;

    .content-box {
      width: 252px;
      margin: auto;
      padding: 40px 10px;
    }
  }

  .home-feed-back .txt-btm .left-btm p {
    font-size: 14px;
  }

  .delivery_product {
    height: auto !important;

    .file-upload-input {
      width: 100%;
    }
  }

  .tl-log-upload-box {
    .log-upload-box {
      p {
        font-size: 13px;
      }

      img {
        width: 40px;
      }
    }
  }

  .plan-tl-box .top-plan-box {
    margin: auto;
    width: 320px;
  }

  .bread-crumb {
    padding: 10px 5px;
    margin-left: auto;
    margin-right: auto;
  }

  .input-box {
    padding: 10px 0;

    input {
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff,
        2px 2px 3px -2px #d5d5d5;
      border: 1px solid #e2e2e2 !important;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff, 2px 2px 3px -2px #d5d5d5;
      border: 1px solid #e2e2e2 !important;
    }
  }

  .rit-auth .box input,
  .form-box .box input {
    box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff,
      2px 2px 3px -2px #d5d5d5;
    border: 1px solid #e2e2e2 !important;
    -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff, 2px 2px 3px -2px #d5d5d5;
    border: 1px solid #e2e2e2 !important;
  }

  .team-circle {
    .img-box-met {
      width: 90px !important;
      height: 90px !important;
      margin: 5px !important;
      border: 3px solid #fff;
    }

    .img-box-met1 {
      width: 100px !important;
      height: 100px !important;
      margin: 5px 10px !important;
      border: 3px solid #fff;
    }
  }

  .dash-board-banner {
    height: auto;

    .carousel-inner {
      .caption-banner {
        .left-banner {
          width: 100%;

          h3 {
            width: 100%;
          }

          button {
            position: static !important;
            margin-bottom: 20px;
          }
        }

        .rit-banner {
          width: 80%;
        }
      }
    }
  }

  .prod-list-login {
    width: 100% !important;
    margin: auto;

    .prod-list-box .btm-prod h3 {
      font-size: 15px;
    }
  }

  .add-cart {
    left: 2%;

    .inner-box {
      width: 100%;

      .close-btn {
        position: absolute;
        top: -10px;
        right: 0px;
      }

      .btn-top {
        width: 100px !important;
      }
    }
  }

  .product-list-detail {
    h3 {
      width: 130px;
    }

    .box-shadow-product {
      width: 100% !important;
    }
  }

  .product-list-detail {
    .box-shadow-product {
      padding: 10px 1px 10px;
    }
  }

  .right-box-cont {
    width: 275px;
  }

  //Kaviya Design
  .splitView {
    height: 18px !important;
    width: 3px !important;
    margin: 0px 8px 15px 0px !important;
  }

  .naviPtag {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    justify-self: center;
    align-self: center !important;
    align-items: center !important;
    padding: 1px 5px 1px 5px !important;
  }

  .arrowImg1 {
    height: 20px !important;
    width: 20px !important;
  }

  .update-qty {
    width: 100% !important;
  }

  .profile-screen {
    padding: 20px 15px;

    .profile-input {
      flex-direction: column;
      margin-bottom: 0;

      .box {
        width: 100%;
        padding-left: 0;
      }
    }

    .profile-action {
      width: 80%;
    }
  }

  .upload-box {
    flex-direction: column;
    width: 80%;
    margin-bottom: 10px;

    div {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .file-upload {
    .upload-btn {
      padding: 6px 18px;
    }
  }

  .membership {
    flex-direction: column;

    .plan {
      width: 90%;
      align-self: start;
      margin-bottom: 20px;
    }
  }

  .benefits {
    width: 80%;
  }

  .sideDetails {
    height: 40%;
    width: 47% !important;
    position: fixed;
    right: 0;
    bottom: 80px !important;
  }

  .productImgReOrer {
    height: 260px !important;
    width: 260px !important;
    margin-top: 40px;
  }

  .shippingPrice-inputs {
    flex-direction: column;

    div {
      width: 100%;
    }
  }

  .form-box-cont {
    height: 200px !important;
    width: 200px !important;
  }

  .text-custom-res {
    text-align: center !important;
  }
}

//max-576px-end
//
//
//
//576px-767-start
@media (min-width: 576px) and (max-width: 767px) {
  .plan-tl-box .top-plan-box {
    width: calc(50% - 30px);
  }

  .prod-list-box-tl {
    width: calc(50% - 30px);
  }

  .home-tab {
    .contain {
      flex-wrap: wrap;
    }

    .pac-box {
      width: 48%;

      .pac-box1 {
        margin-bottom: 10px;

        h5 {
          font-size: 16px !important;
        }
      }
    }
  }

  .rit-send-merch {
    .content-box {
      img {
        width: 60px !important;
      }
    }
  }

  .rit-swag .swag-box {
    margin: 10px !important;
  }

  .tl-pack {
    padding-bottom: 80px;
  }

  .dash-board-banner {
    height: 280px;

    .carousel-inner {
      .caption-banner {
        .left-banner {
          width: 50%;

          h3 {
            width: 100%;
          }
        }

        .rit-banner {
          width: 50%;
        }
      }
    }
  }

  .add-cart {
    width: 90%;
    left: 5%;

    .inner-box {
      width: 100%;

      .close-btn {
        position: absolute;
        top: -10px;
        right: -10px;
      }

      .btn-top {
        width: 100px !important;
      }
    }
  }

  .membership .plan {
    width: 50%;
  }

  .benefits {
    width: 80%;
  }
}

//576px-767-end
//
//
//
//min-768-start
@media (max-width: 767.99px) {
  .serving-for {
    .logos {
      width: 80%;
      margin: 0px auto 40px;

      img {
        width: 40px !important;
        height: auto;
      }
    }

    h3 {
      margin-top: 50px !important;
    }
  }

  .prod-list-login {
    width: 50%;

    .prod-list-box .btm-prod h3 {
      font-size: 15px;
    }
  }

  .custom-palce {
    ::placeholder {
      font-size: 14px;
    }
  }

  .owl-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .owl-prod {
    width: 220px !important;
  }

  .pre-products {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filterView {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .filterView1 {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin-left: 100px;
    margin-right: 100px;
  }

  .dash-board-banner,
  .whats-new {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 10px;

    .box {
      border-radius: 10px;
    }
  }

  .team-circle {

    .img-box-met,
    .img-box-met1 {
      width: 170px;
      height: 170px;
      margin: 20px 20px;
    }
  }

  .plan-tl-box {
    .list {
      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .trans-range .pricing-pc-box {
    .rit-pack-sample {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
    }

    .range-slider__thumb[data-upper] {
      width: 15px;
      height: 15px;
    }
  }

  .question-ans .left-quest .search-faq img {
    width: 20px;
    height: 20px;
  }

  .home-tab {
    .tab-tl {
      overflow: scroll;

      .swag-wild-btn {
        button {
          width: 120px;
        }
      }
    }
  }

  .delivery_product {
    img {
      width: 50px;
    }

    h6 {
      font-size: 14px !important;
    }

    p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  .modal-popup {
    .modal-cls-btn {
      width: 30px !important;
      height: 30px !important;
    }

    .left-popup {
      .owl-dots {
        margin: -10px auto 30px !important;
      }

      .owl-dot {
        width: 20px !important;
        height: 5px !important;
      }
    }
  }

  .pack-title {
    font-size: 16px;
  }

  .prod-list-box-tl {
    .prod-list-box {
      .btm-prod {
        h3 {
          font-size: 14px;
          height: 60px;
        }

        p {
          font-size: 14px;
        }

        button {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .rit-prod {
    .disc {
      line-height: 25px;
      text-align: justify;
    }
  }

  .sign-up {
    padding: 10px;

    .log-logo {
      position: initial;
    }

    .left-auth {
      width: 80%;
      margin-top: 20px;
      text-align: center;
    }

    .rit-auth {
      .form-box {
        padding: 11px 15px;
        width: 350px !important;
        margin-top: 10px !important;

        h5 {
          font-size: 15px;
        }

        .box {
          margin-top: 0 !important;

          p {
            font-size: 13px;
          }

          input {
            font-size: 15px;
          }
        }
      }
    }
  }

  .input-box {
    margin-top: 0 !important;

    p {
      font-size: 13px;
    }

    input {
      font-size: 15px;
    }
  }

  .sign-btn {
    justify-content: center !important;
    width: 43px !important;
    height: 36px !important;
    padding: 0 !important;
    border-radius: 5px !important;

    .user-icon {
      width: 22px !important;
      height: 22px !important;
      margin-left: 0 !important;
    }
  }

  .ftr-btm {
    width: 100%;
  }

  .ftr-list {
    width: 44% !important;
  }

  .list-pack,
  .list-pack1 {
    margin-left: 10%;

    p {
      width: 80%;
    }
  }

  .pac-img {
    width: 200px;
    margin: auto;
    display: block;
  }

  .rit-swag .swag-box .btn-merch {
    width: 180px !important;
  }

  .parag {
    font-size: 15px !important;
  }

  .parag1 {
    font-size: 13px !important;
  }

  .menu-item {
    margin-top: 0px;
    position: fixed;
    top: 0;
    background: #07679c;
    width: 100%;
    height: calc(100vh - 0px);
    transition: 0.2s all linear;
    z-index: 2;
    padding-top: 40px;

    .closebtn {
      position: fixed;
      z-index: 111;
      top: 7px;
      right: 7px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      justify-content: center;
      box-shadow: 2px 4px 2px -2px #0f4967, inset -3px -3px 11px 8px #0c6797,
        inset 3px 3px 15px 20px #7ed2ff;
      -webkit-box-shadow: 2px 4px 2px -2px #0f4967,
        inset -3px -3px 11px 8px #0c6797, inset 3px 3px 15px 20px #7ed2ff;
      background: #07679c;

      svg {
        font-size: 20px;
        color: #fff;
      }
    }

    .left-imgs {
      width: 40px !important;
      height: 40px !important;
    }

    a,
    .menu-head-btn {
      font-size: 14px !important;
      font-family: "f2" !important;
      color: #fff !important;
      padding: 10px 30px !important;
      border-bottom: 2px solid #0a5986 !important;
      border: 0;
      // background: #0570ac;
    }

    .drop-list {
      background: #105276 !important;
      border: 0px solid #d7dff1 !important;
      box-shadow: 0 !important;
      -webkit-box-shadow: 0 !important;
      padding: 5px 10px !important;
      border-radius: 0 !important;
      width: 100% !important;

      h5 {
        font-family: "f2" !important;
        color: #fff !important;
      }
    }

    .menu-list {
      width: 100% !important;
    }
  }

  .home-banner {
    padding: 0px 5% !important;
    width: 100% !important;
    margin-left: 0 !important;
    flex-direction: column-reverse;
  }

  .rit-send-merch {
    .back-img {
      width: 100% !important;
    }

    .content-box h5 {
      font-size: 17px !important;
    }

    p {
      font-size: 16px !important;
    }
  }

  .sign-drop-list {
    background: #105276 !important;
    border: 0px solid #d7dff1 !important;
    box-shadow: 0 !important;
    -webkit-box-shadow: 0 !important;
    padding: 5px 10px !important;
    border-radius: 0 !important;

    h5 {
      font-family: "f2" !important;
      color: #fff !important;
    }
  }

  .banner-rit {
    width: 100%;

    img {
      width: 290px;
      margin: 30px auto !important;
      display: block;
    }
  }

  .logos {
    width: 80% !important;
  }

  .serving-for .logos {
    padding: 16px 9px !important;
    border-radius: 12px !important;
  }

  .improsive-feature {
    width: calc(90% - 10px) !important;

    .rit-improsive-feature {
      width: 250px;
      // margin-bottom: 40px;
      margin: 0 auto 40px;

      img {
        width: 100%;
        margin: auto;
        display: block;
      }
    }
  }

  .send-merch {
    width: calc(90% - 10px) !important;
  }

  .bac-btm-img {
    width: 100% !important;
    margin: auto;
  }

  .home-support {
    .left-img {
      border-right: 0px solid #e5e5e5 !important;
      flex-direction: column !important;
      justify-content: center;

      .support-img {
        width: 120px;
        margin-right: 20px;
        margin-left: 45px;
      }

      p {
        width: 90% !important;
        padding-right: 10px;
        text-align: center;
        margin: auto;
      }

      .support-cont-home {
        img {
          width: 30px;
        }

        h4 {
          font-size: 17px;
        }
      }
    }
  }

  .home-support {
    .left-img11 {
      flex-direction: column !important;
      justify-content: center;

      .support-img {
        width: 120px;
        margin-right: 20px;
        margin-left: 45px;
      }

      p {
        width: 90% !important;
        padding-right: 10px;
        text-align: center;
        margin: auto;
      }

      .support-cont-home {
        img {
          width: 30px;
        }

        h4 {
          font-size: 17px;
        }
      }
    }
  }

  .rit-swag {
    .swag-box {
      width: 100%;
      margin: 0px 0 10px;
      padding: 30px;

      h5 {
        font-size: 16px !important;
      }

      img {
        width: 22px !important;
      }

      h3 {
        font-size: 22px !important;
      }

      p {
        font-size: 15px !important;
      }

      line-height: 50px !important;
    }
  }

  .rit-send-merch {
    width: 50%;

    .content-box {
      width: 280px;
      margin: auto;
      padding: 20px 10px;
    }
  }

  .bread-crumb {

    a,
    svg {
      font-size: 13px;
    }
  }

  .how-work {
    img {
      width: 150px;
    }

    h5 {
      width: 22px;
      height: 22px;
    }
  }

  .plan-tl-detail {
    width: 100%;
    overflow-x: scroll;

    .plan-detail {
      width: 800px;
    }
  }

  .let-start-box {
    img {
      width: 70px;
    }
  }

  .upload-box {
    width: 90%;
  }

  .profile-screen {
    .profile-input {
      // width: 90%;
    }
  }

  .benefits {
    width: 60%;
  }

  //Kaviya Design
  .profile-screen1 {
    width: 100%;
    padding: 20px 30px;

    .profile-input {
      width: 100%;
      display: grid;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .box1 {
        width: 100%;
        margin-top: 10px;
        padding: 10px;

        select {
          font-size: 18px !important;
          font-family: f2;
          width: 230% !important;
          // color: $primary1;
          height: 48px;
          background: #f3f7ff;
          border-radius: 10px;
          border: 0;
          box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          padding: 0 20px;
        }

        input {
          font-size: 18px;
          font-family: f2;
          width: 100%;
          height: 48px;
          background: #f3f7ff;
          border-radius: 10px;
          border: 0;
          box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          padding: 0 20px;

          &::placeholder {
            color: #d1def9;
          }
        }
      }

      .boxShirt {
        width: 100%;
        margin-top: 10px;
        padding: 10px;

        select {
          font-size: 18px !important;
          font-family: f2;
          width: 165% !important;
          // color: $primary1;
          height: 48px;
          background: #f3f7ff;
          border-radius: 10px;
          border: 0;
          box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          padding: 0 20px;
        }

        input {
          font-size: 18px;
          font-family: f2;
          width: 100%;
          height: 48px;
          background: #f3f7ff;
          border-radius: 10px;
          border: 0;
          box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          padding: 0 20px;

          &::placeholder {
            color: #d1def9;
          }
        }
      }

      .box {
        width: 100%;
        margin-top: 10px;
        padding: 10px;

        select {
          font-size: 18px !important;
          font-family: f2;
          width: 100% !important;
          color: $primary1;
          height: 48px;
          background: #f3f7ff;
          border-radius: 10px;
          border: 0;
          box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          padding: 0 20px;
        }

        input {
          font-size: 18px;
          font-family: f2;
          width: 100%;
          height: 48px;
          background: #f3f7ff;
          border-radius: 10px;
          border: 0;
          box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          padding: 0 20px;

          &::placeholder {
            color: #d1def9;
          }
        }
      }

      .box4 {
        width: 86%;
        margin-top: 10px;
        padding: 10px;

        select {
          font-size: 18px !important;
          font-family: f2;
          width: 100% !important;
          color: $primary1;
          height: 48px;
          background: #f3f7ff;
          border-radius: 10px;
          border: 0;
          box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          padding: 0 20px;
        }

        input {
          font-size: 18px;
          font-family: f2;
          width: 100%;
          height: 48px;
          background: #f3f7ff;
          border-radius: 10px;
          border: 0;
          box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
            inset -5px -5px 9px 1px #fff;
          padding: 0 20px;

          &::placeholder {
            color: #d1def9;
          }
        }
      }
    }

    .box {
      width: 100%;
      margin-top: 10px;
      padding: 10px;

      select {
        font-size: 18px !important;
        font-family: f2;
        width: 100% !important;
        color: $primary1;
        height: 48px;
        background: #f3f7ff;
        border-radius: 10px;
        border: 0;
        box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
        -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
          inset -5px -5px 9px 1px #fff;
        padding: 0 20px;
      }

      input {
        font-size: 18px;
        font-family: f2;
        width: 100%;
        height: 48px;
        background: #f3f7ff;
        border-radius: 10px;
        border: 0;
        box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
        -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
          inset -5px -5px 9px 1px #fff;
        padding: 0 20px;

        &::placeholder {
          color: #d1def9;
        }
      }
    }

    .box2 {
      width: 100%;
      margin-top: 10px;
      padding: 10px;

      select {
        font-size: 18px !important;
        font-family: f2;
        color: $primary1;
        border-radius: 10px;

        height: 48px;
        background: #f3f7ff;
        border: 0;
        box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
        -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
          inset -5px -5px 9px 1px #fff;
        padding: 0 20px;
      }

      input {
        font-size: 18px;
        font-family: f2;
        width: 70%;
        height: 48px;
        margin-left: 40px;
        background: #f3f7ff;
        border-radius: 10px;
        border: 0;
        box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
        -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
          inset -5px -5px 9px 1px #fff;
        padding: 0 20px;

        &::placeholder {
          color: #d1def9;
        }
      }
    }

    .profile-action {
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px;

      button {
        width: 46%;
        height: 47px;
        margin-top: 0px;
        font-size: 16px;
        font-family: "f3";
      }
    }
  }

  .continueBtn2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .text-sx-center {
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .sideDetails {
    height: 40%;
    width: 40%;
    position: fixed;
    right: 0;
    bottom: 70px;
  }

  .productImgReOrer {
    height: 400px;
    width: 400px;
    margin-top: 40px;
  }

  .meet-circle {
    .bg-team-border {
      h6 {
        height: 25px;
      }
    }
  }

  .custom-img-form {
    // width: 100% !important;
    // height: 100% !important;
    margin-top: 2rem !important;
    height: 200px !important;
    width: 200px !important;
  }

  .form_cont {
    height: 70% !important;
    width: 90% !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .text-custom-align {
    text-align: center !important;
  }

  .btn-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg-team-border {
    background: #f2f7ff;
    padding: 10px;
    border-radius: 13px;
    margin: 0 2px;

    img {
      margin-top: -3px;
      border-radius: 13px;
    }
  }

  .blog-Text {
    margin-top: 20px;
  }

  .bulk-prod {
    width: calc(100% - 60px) !important;
    margin-left: 55px !important;
  }

  .filter-menu-build {
    width: 90% !important;
  }
}

//max-767-end
//
//
//
//min-768-start
@media (min-width: 768px) {
  .prod-list-login {
    width: 33%;

    .prod-list-box .btm-prod h3 {
      font-size: 15px;
    }
  }

  .custom-res-hw {
    width: 25% !important;
  }

  .margin-res {
    margin-left: 1rem;
    margin-right: 3rem;
  }

  .plan-tl-box .top-plan-box {
    width: 50%;
  }

  .blog-boxes {
    .blog-box {
      margin-bottom: 50px;

      &:nth-child(odd) {
        margin-left: 0 !important;
      }

      &:nth-child(even) {
        margin-right: 0 !important;
        margin-left: auto !important;
      }
    }
  }

  .out-left {
    aside {
      .header-rit-top {
        position: fixed;
        width: 100%;
        right: 0;
        padding-left: 250px !important;
      }
    }
  }

  .btm-header-rit-top {
    height: 60px;
  }

  .top-header {
    .menu-item {

      a,
      .menu-head-btn {
        padding: 10px 10px;
        padding-bottom: 0;
      }
    }
  }

  .banner-rit {
    width: 100%;

    img {
      width: 74%;
      margin: 30px auto !important;
      display: block;
    }
  }

  .rit-send-merch {
    .back-img {
      width: 100%;
    }
  }

  .rit-swag {
    .swag-box {

      // width: 370px;
      h5 {
        font-size: 16px;
      }

      img {
        width: 22px;
      }

      h3 {
        font-size: 22px;
      }

      p {
        font-size: 15px;
      }
    }
  }

  .drop-list {
    position: absolute;
  }

  .upload-box {
    width: 90%;
  }

  .profile-screen {
    .profile-input {
      width: 90%;
    }
  }

  .margin-c {
    margin-right: 20px !important;
  }
}

//min-768-end
//
//
//
//
//768-991-start
@media (min-width: 768px) and (max-width: 991px) {

  .menu-item a,
  .menu-item .menu-head-btn {
    font-size: 15px !important;
  }

  .position-static-custom {
    position: static !important;
  }

  .whats-new .box img {
    width: 60px;
  }

  .dash-board-banner {
    height: 220px;
  }

  .plan-tl-box {
    .list {
      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .rit-send-merch {
    width: 50% !important;

    .content-box {
      width: 300px;
      margin-left: 3%;
      padding: 40px 10px;
    }
  }

  .top-header {
    .sign-btn {
      width: 90px !important;
      height: 40px !important;
      border-radius: 5px !important;
      align-items: center !important;
      background: #cde2fe !important;
      padding: 0 8px !important;

      svg {
        width: 24px !important;
      }

      .user-icon {
        width: 25px !important;
        height: 25px !important;
      }
    }
  }
  .product-list-detail .box-shadow-product_view {
    padding: 10px 30px 20px;
    width: 270px !important;
  }

  .product-list-detail .box-shadow-product {
    padding: 10px 30px 20px;
    width: 270px !important;
  }

  .pack-title {
    font-size: 21px;
  }

  .prod-list-box-tl {
    .prod-list-box {
      .btm-prod {
        h3 {
          font-size: 14px;
        }

        p {
          font-size: 14px;
        }

        button {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .prod-list-box-tl {
    width: 50%;
  }

  .owl-prod {
    width: 450px !important;
  }

  .prod-owl-img {
    height: 140px !important;
  }

  .left-product {
    .cat-title {
      padding: 15px;

      svg {
        width: 27px;
      }

      p {
        font-size: 15px;
      }
    }

    .list-box {
      padding: 0 7px;
      width: 300px;

      p {
        font-size: 15px;
      }

      .active {
        width: 39px;
        height: 39px;
      }

      .img-back {
        width: 39px;
        height: 39px;
      }
    }
  }

  .sign-up {
    .rit-auth {
      .form-box {
        h5 {
          font-size: 19px;
        }

        .box {
          p {
            font-size: 14px;
          }

          input {
            font-size: 17px;
          }
        }
      }
    }
  }

  .input-box {
    p {
      font-size: 14px;
    }

    input {
      font-size: 17px;
    }
  }

  .home-tab {
    .contain {
      flex-wrap: wrap;
    }

    .pac-box {
      width: 48%;

      .pac-box1 {
        margin-bottom: 10px;

        h5 {
          font-size: 16px !important;
        }
      }
    }
  }

  .tl-pack {
    padding-bottom: 70px;
  }

  .left-img {
    .support-img {
      width: 110px;
      margin-right: 20px;
    }

    p {
      width: 380px;
      padding-right: 10px;
    }

    .support-cont-home {
      h4 {
        font-size: 20px;
      }

      img {
        width: 33px;
      }
    }
  }

  .bac-btm-img {
    width: 115% !important;
    margin: auto;
  }

  .top-header .menu-item .drop-list {
    border: 2px solid #d7dff1 !important;
  }

  .sign-drop-list {
    border: 2px solid #d7dff1 !important;
  }

  .home-tab {
    .pac-box {
      width: 49%;
    }
  }

  .ftr-list {
    width: 30%;

    &:nth-child(4) {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }

  .benefits {
    width: 60%;
  }
}

//768-991-end
//
//
//
//
//max-991-start
@media (max-width: 991px) {
  .abt-hq .box-mis-vis {
    height: 230px;
  }

  .inner-ship-stor {
    width: 100% !important;
  }

  .custom-bottom-res {
    display: none !important;
  }

  .align-center-custom {
    align-items: center !important;
    justify-content: center !important;
  }

  .align-center-custom2 {
    align-items: start !important;
    justify-content: start !important;
  }

  .w-custom-form {
    width: 90% !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .custom-s {
    width: 100% !important;
  }

  .prod-list-login {
    width: 50%;

    .prod-list-box .btm-prod h3 {
      font-size: 15px;
    }
  }

  .blog-enquiry {
    .rit-loop {
      .stay-loop {
        input {
          height: 34px;
          border-radius: 5px;
          padding: 0 8px;
        }
      }
    }
  }

  .out-left {
    padding-top: 70px;
  }

  .bulk-prod {
    padding-top: 40px;
    margin-left: 70px;
    width: calc(100% - 75px);
    height: calc(100vh - 55px);
  }

  .left-mock-req {
    height: calc(100vh - 60px);
  }

  .tl-shipment-screen {
    width: 100%;

    .req-summary {
      transition: 0.2s all linear;
      width: 290px;
      height: calc(100vh - 100px);
      top: 80px;
    }
  }

  .close-btn11 {
    position: fixed;
    top: 80px;
    z-index: 1;
    transition: 0.2s all linear;
    background: #fff !important;
  }

  .prod-cart {
    width: 290px;
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    background: $lt-blue2;
    overflow-y: scroll;
  }

  .owl-kits {
    width: 110.8px !important;
    height: 170px !important;
  }

  .owl-products {
    width: 160.8px !important;
    height: 220px !important;
  }

  .item {
    height: 160px !important;
  }


  .left-product {
    height: calc(100vh - 55px);
  }

  .preprod-pack {
    .rit-pop {
      width: 80% !important;
      margin: auto !important;
      align-items: start !important;
    }
  }

  .modal-popup {
    .center-content {
      overflow: scroll;
      width: 80%;
      height: 80vh;
      top: 16vh;

      .left-popup {
        width: 80% !important;
        height: auto !important;
        justify-content: center !important;
        border-radius: 0;

        .imges {
          // width: 90% !important;
          // margin-left: 0% !important;
          // margin-right: 0 !important;
          // margin-top: 0px !important;
          height: 90%;
          border-radius: 10px !important;
          overflow: hidden;

          img {
            width: 60%;
          }
        }
      }

      .rit-pop {
        width: 100%;
        // background: #f4f8ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding-left: 0 !important;

        h2 {
          font-size: 18px !important;
        }

        h3 {
          font-size: 16px !important;
        }

        .qunt-btn {
          width: 33px !important;
          height: 33px !important;
        }
      }
    }
  }

  .left-product {
    overflow-x: hidden;
    position: fixed;
    overflow-y: scroll;
    left: 0;
    top: 70px;
    background: #fff;

    .cat-title {
      padding: 15px;
    }

    .list-box {
      padding: 0 5px;
      width: 300px;

      p {
        font-size: 15px;
      }

      .active {
        width: 39px;
        height: 39px;
      }

      .img-back {
        width: 39px;
        height: 39px;
      }
    }
  }

  .ftr-list {
    h5 {
      font-size: 16px;
    }

    a {
      font-size: 16px;
    }
  }

  .rit-ftr {
    width: calc(100% - 0px);
  }

  .footer .social-ftr-lnk {
    justify-content: center;

    .social-footer-icon {
      width: 40px;
      height: 40px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .home-feed-back {
    .box {
      width: 101%;
      margin-bottom: 0px;
      margin-top: -20px;
      margin-left: -10px;
    }

    .left-box {
      width: 110px;
      height: 110px;
    }

    .rit-box {
      width: 70%;
      margin-left: 15px;
    }

    .left-btm {
      h5 {
        font-size: 16px !important;
        // margin-left: 20px !important;
      }
    }
  }

  .banner-left {
    width: 100%;

    p {
      text-align: justify;
    }
  }

  .home-banner .banner-left h4 {
    font-size: 19px;
  }

  .serving-for {
    z-index: 0;
    position: relative;
    margin: 10px;

    h3 {
      font-size: 17px;
      margin-bottom: 0;
    }

    p {
      margin-top: 0px;
      line-height: 20px;
    }
  }

  .improsive-feature {
    width: calc(80% - 10px);

    .left-improsive-feature {
      width: 95%;
      margin: 10px auto 0px;

      h4 {
        font-size: 17px;
      }

      img {
        width: 15px;
        height: 15px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .send-merch {
    flex-direction: column-reverse;
    width: calc(80% - 10px);
    margin: 10px auto 0px;

    .left-send-merch {
      width: 95%;
      margin: 0px auto 0px;
      text-align: center;

      h4 {
        font-size: 17px;
      }

      img {
        width: 15px;
        height: 15px;
      }

      p {
        font-size: 14px;
        line-height: 25px;
        margin-top: 0;
      }
    }
  }

  .sect-title {
    font-size: 20px;
  }

  .banner-left {
    width: 100%;
  }

  .top-header {
    width: 100%;
    // padding: 0 5%;
    position: fixed;
    background: #fff;
    z-index: 1;

    .demo-start {
      a {
        border: 2px solid #07679c !important;
        font-size: 12px;
        padding: 0 5px !important;
        height: 31px;
        border-radius: 6px;
      }
    }
  }

  .get-start-btn {
    width: 140px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 10px;
    color: #fff;
    font-size: 12px;
    font-family: "f3";
    background: #07679c;
  }

  .get-start-btn1 {
    width: 140px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: auto 0 auto auto;
    color: #fff;
    font-size: 12px;
    font-family: "f3";
    background: #07679c;
  }

  .rit-send-merch {
    .content-box {
      img {
        width: 45px;
        margin-bottom: 4px;
      }
    }
  }

  .left-product-hid {
    width: 53px;
    transition: 0.2s all linear;
  }

  .left-product-show {
    width: 290px;
    transition: 0.2s all linear;
  }

  .update-qty-custom {
    width: 100% !important;
  }

  .right-box-cont {
    width: 95% !important;
  }

  .box-shadow-product-res {
    width: 100% !important;
  }

  .profile-screen {
    margin-left: 60px;

    .addReciView {
      width: 70%;
    }
  }

  .input-custom {
    width: 80% !important;
  }

  .align-custom1 {
    align-items: center !important;
    justify-content: center !important;
  }

  .margin-custom {
    padding-left: 3% !important;
    margin-left: 3% !important;
  }

  .cust-w {
    width: 90% !important;
  }

  .w-s2 {
    width: 90% !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex;
    flex-direction: column !important;
    margin-bottom: 20px !important;
  }

  .vs-s {
    width: 100% !important;
    align-self: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .cust-hv {
    width: 90% !important;
  }

  .address-box button {
    border-style: none;
    background: none;
    color: #07679c;
    font-size: 16px;
    font-family: "f3";
  }

  .whats-new .box {
    width: 100%;
    margin-left: 0;
  }
}

//max-991-end
//
//
//
//
//min-992-start
@media (min-width: 992px) {
  .product-zoom-color {
    width: calc(100% - 400px);
  }

  .cust-w {
    width: 80% !important;
  }

  .right-comp {
    position: fixed !important;
  }

  .prod-list-login {
    width: 25%;

    .prod-list-box .btm-prod h3 {
      font-size: 15px;
    }
  }

  .simple-pack1 {
    .pac-img1 {
      width: 300px;
    }
  }

  .rit-pack-sample {
    width: 300px;
  }

  .plan-tl-box .top-plan-box {
    width: 33.33%;
  }

  .question-ans {
    margin-top: 80px !important;
  }

  .visit-help {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 340px;
    border-radius: 10px;
    padding: 15px 25px;

    img {
      width: 130px;
    }
  }

  .blog-enquiry {
    .rit-loop {
      .stay-loop {
        input {
          height: 47px;
        }
      }
    }

    .blog-prod {
      width: 250px;
    }
  }

  .rit-swag {
    .swag-box {

      // width: 340px;
      h5 {
        font-size: 14px;
      }

      img {
        width: 20px;
      }

      h3 {
        font-size: 20px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  .top-header {
    .menu-item {

      a,
      .menu-head-btn {
        padding: 10px 20px;
        padding-bottom: 0;
      }
    }
  }

  .banner-left {
    width: 50%;
  }

  .home-banner {
    .banner-left {
      h4 {
        font-size: 29px;
      }
    }
  }

  .serving-for {
    h3 {
      font-size: 29px;
    }
  }

  .improsive-feature {
    .left-improsive-feature {
      width: 80%;

      // margin: 10px auto 50px;
      h4 {
        font-size: 29px;
        margin-bottom: 20px;
      }

      img {
        width: 20px;
        height: 20px;
      }

      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  .sect-title {
    h4 {
      font-size: 29px;
      // margin-bottom: 20px;
    }
  }

  .send-merch {
    .left-send-merch {
      width: 80%;
      margin: 0px auto 0px;

      h4 {
        font-size: 29px;
        margin-bottom: 20px;
      }

      img {
        width: 20px;
        height: 20px;
      }

      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  .sect-title {
    font-size: 29px;
    // margin-bottom: 20px;
  }

  .banner-left {
    width: 50%;
  }

  .banner-rit {
    width: 50%;

    img {
      width: 420px;
      // height: 300px;
    }
  }

  .top-header {
    .demo-start {
      a {
        border: 2px solid #07679c;
        font-size: 17px;
      }
    }
  }

  .get-start-btn {
    width: 180px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 37px;
    color: #fff;
    font-size: 17px;
    font-family: "f3";
    background: #07679c;
  }

  .get-start-btn1 {
    width: 170px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: auto 0 auto auto;
    color: #fff;
    font-size: 15px;
    font-family: "f3";
    background: #07679c;
  }

  .send-merch {
    width: 85%;
  }

  .box-merch {
    width: 85%;
  }

  .rit-send-merch {
    .content-box {
      img {
        width: 80px;
        margin-bottom: 16px;
      }
    }
  }

  .rit-pop {
    overflow: scroll;
  }

  .prod-pac-box {
    width: 42% !important;
  }

  .upload-box {
    width: 80%;
  }

  .profile-screen {
    .profile-input {
      width: 80%;
    }
  }

  // .prod-list-box-tl2 {
  //   width: 24% !important;
  //   .prod-list-box-tl .prod-list-box {
  //     padding: 40px;
  //     border-radius: 35px;
  //   }
  // }
  .prod-list-box-tl2 {
    width: 24% !important;

    .prod-list-box-tl .prod-list-box {
      padding: 40px;
      border-radius: 35px;
      display: flex !important;
    }
  }

  .address-box button {
    border-style: none;
    background: none;
    color: #07679c;
    font-size: 18px;
    font-family: "f3";
  }
}

//992-end
//
//
//
//
//1199-start

@media (min-width: 992px) and (max-width: 1199px) {
  .abt-hq .box-mis-vis {
    height: 230px;
  }

  .dash-board-banner,
  .whats-new {
    height: 300px;
  }

  .plan-tl-box {
    justify-content: center;
  }

  .rit-prod {
    margin-top: 90px;
    margin-left: 5px;
    width: calc(100% - 280px);
    height: calc(100vh - 55px);
  }

  .left-mock-req {
    height: calc(100vh - 60px);
  }

  .tl-shipment-screen {
    width: calc(100% - 280px);

    .req-summary {
      width: 280px;
      height: calc(100vh - 100px);
      top: 80px;
    }
  }

  .prod-cart {
    width: 280px;
    height: calc(100vh - 60px);
    position: absolute;
    right: 0;
    top: 60px;
    background: $lt-blue2;
    overflow-y: scroll;
  }

  .left-product {
    height: calc(100vh - 55px);
  }

  .box-merch {
    width: 100%;
  }

  .rit-send-merch {
    width: 33.33% !important;

    .content-box {
      width: 230px;
      margin-left: 3%;
      padding: 30px 10px;
    }
  }

  .modal-popup {
    .center-content {
      width: 900px;

      .left-popup {
        // width: 580px;
        height: 100%;
        padding-left: 50px;

        .imges {
          width: 390px;
          height: 390px;
        }
      }
    }
  }

  .pack-title {
    font-size: 18px;
  }

  .prod-list-box-tl {
    .prod-list-box {
      .btm-prod {
        h3 {
          font-size: 15px;
        }

        p {
          font-size: 15px;
        }

        button {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .prod-list-box-tl {
    width: 50%;
  }

  .home-tab {
    .pac-box {
      width: 30%;

      .pac-box1 h5 {
        font-size: 16px !important;
      }
    }
  }

  .left-img {
    .support-img {
      width: 140px;
      margin-right: 20px;
    }

    p {
      width: 450px;
      // width: 27.33%;
    }

    .support-cont-home {
      h4 {
        font-size: 18px;
      }

      img {
        width: 33px;
      }
    }
  }

  .bac-btm-img {
    width: 115% !important;
    margin: auto;
  }

  .home-feed-back {
    .box {
      width: 60%;
      margin-bottom: 0px;
      margin-top: -20px;
      margin-left: -20px;
    }

    .left-box {
      width: 110px;
      height: 110px;
    }

    .rit-box {
      width: 70%;
      margin-left: 15px;
    }
  }



  .top-header {
    width: 100%;

    // padding: 0 10%;
    .sign-btn {
      width: 90px !important;
      height: 40px !important;
      border-radius: 5px !important;
      align-items: center !important;
      background: #cde2fe !important;
      padding: 0 8px !important;

      svg {
        width: 24px !important;
      }

      .user-icon {
        width: 25px !important;
        height: 25px !important;
      }
    }
  }

  .benefits {
    width: 60%;
  }

  .whats-new {
    width: 330px;
  }
}

//992-1199-end
//
//
//
//
//1199-start

@media (max-width: 1199px) {
  .show-cart {
    right: 0px;
    margin-left: 0 !important;
    visibility: visible;
  }

  .hide-cart {
    right: -100%;
    margin-left: 0 !important;
    visibility: hidden;
  }

  .left-auth,
  .reg-auth-rt {
    width: 100%;
    text-align: center;
  }

  .rit-auth .form-box {
    margin: 0 auto 30px;
  }

  .tableCellStyle {
    img {
      width: 50px;
      height: 50px;
    }
  }
}

@media (max-width: 769px) {
  .loop {
    .get-start-btn {
      margin-top: 5px !important;
    }
  }
}

//1199-end
//
//
//
//
//1200-start
@media (max-width: 769px) {
  .top-header {
    width: 100% !important;
    padding: 0 10px;

    .logo {
      width: 100px !important;
    }
  }

  .sign-btn {
    width: 30px !important;
    height: 29px !important;
  }

  .sign-btn .user-icon {
    width: 19px !important;
    height: 19px !important;
  }

  .menu-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .rit-swag .swag-box .list-warn img {
    width: 30px;
    height: 18px;
  }

  .pac-img1 {
    width: 95px;
  }

  .get-start-btn1 {
    padding: 18px;
    width: 150px;
    text-wrap: nowrap;
  }

  .footer .social-ftr-lnk .social-footer-icon {
    width: 33px;
    height: 30px;
  }

  .left-btm {
    margin-left: 20px;
  }

  .pic-cen {
    display: flex !important;
    justify-content: center !important;
  }

  .fuls {
    width: 100% !important;
    // justify-content: center !important;
  }

  .sus-banner {
    height: 200px !important;
  }

  .faq-banner {
    height: 150px !important;
  }

  .get-start-btn {
    width: 125px !important;
  }

  .sign-up .rit-auth .form-box {
    padding: 11px 15px;
    width: 295px !important;
    margin-top: 10px !important;
  }

  .build {
    text-align: center !important;
  }

  .inputcontainer {
    width: 230px;
  }

  .visit-help .get-start-btn {
    width: 100px !important;
  }

  .rangepopup {
    width: 236px !important;
  }

  .filter-options {
    width: 242px !important;
    padding: 15px 20px !important;
  }

  .blogg-img img {
    height: 100%;
  }

  .product-list-detail .box-shadow-product_view {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .border-curv-img {
    img {
      width: 80%;
    }
  }

  .abt-hq .box-mis-vis {
    height: 230px;
  }

  .prod-list-login {
    width: 25%;
  }

  .filter-product {
    input::placeholder {
      color: $primary1;
      font-size: 17px;
      font-family: "f2";
    }

    .search-icon {
      img {
        width: 25px;
      }
    }
  }



  .plan-tl-box .top-plan-box {
    width: 25%;
  }

  .question-ans {
    .faq-img {
      width: 30%;
      padding-right: 20px;
    }
  }

  .visit-help {
    width: 330px;
    border-radius: 16px;
    padding: 15px 25px;

    img {
      width: 130px;
    }
  }

  .blog-enquiry {
    .blog-prod {
      width: 250px;
    }
  }

  .show-cart {
    margin-right: 0px;
    visibility: visible;
  }

  .hide-cart {
    margin-right: -300px;
    visibility: hidden;
  }

  .sign-drop-list {
    position: absolute;
    top: 60px !important;
  }

  .logo {
    h3 {
      font-size: 30px !important;
    }
  }

  .top-header {
    margin: auto;
    padding: 20px;
    height: 100px;
    width: 100%;

    .menu-item {

      a,
      .menu-head-btn {
        padding: 0px 10px;
        padding-bottom: 0;
      }
    }
  }

  .pac-img1 {
    width: 180px;
  }

  .demo-start {
    a {
      padding: 10px 10px !important;
      height: 43px;

      &:first-child {
        margin-right: 20px !important;
      }
    }
  }

  .simple-pack {
    border-radius: 50px !important;
  }

  .get-start-btn {
    width: 180px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 37px;
    color: #fff;
    font-size: 16px;
    font-family: "f3";
    background: #07679c;
  }

  .get-start-btn1 {
    width: 220px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: auto 0 auto auto;
    color: #fff;
    font-size: 17px;
    font-family: "f3";
    background: #07679c;
  }

  .send-merch {
    width: 90%;
  }

  .box-merch {
    width: 90%;
  }

  .rit-swag {
    .swag-box {

      // width: 370px;
      h5 {
        font-size: 16px;
      }

      img {
        width: 22px;
      }

      h3 {
        font-size: 22px;
      }

      p {
        font-size: 15px;
      }
    }
  }

  .home-feed-back {
    .box {
      width: 94%;
      margin-bottom: 0px;
      margin-top: -20px;
      margin-left: -20px;
    }

    .left-box {
      width: 110px;
      height: 110px;
    }

    .rit-box {
      width: 70%;
      margin-left: 15px;
    }
  }

  .tl-log-upload-box {
    label {
      height: 300px;
    }
  }

  .upload-box {
    width: 60%;
  }

  .profile-screen {
    .profile-input {
      width: 80%;
    }
  }

  .address-box button {
    border-style: none;
    background: none;
    color: #07679c;
    font-size: 20px;
    font-family: "f3";
  }

  // .prod-list-box-tl2 {
  //   width: 24% !important;
  //   .prod-list-box-tl .prod-list-box {
  //     padding: 40px;
  //     border-radius: 35px;
  //   }
  // }
  .tableCellStyle {
    img {
      width: 50px;
      height: 50px;
    }
  }

  .whats-new {
    width: 430px;
  }
}

//1200-end
//
//
//
//
//
//1200-1399-start

@media (min-width: 1200px) and (max-width: 1399px) {

  .dash-board-banner,
  .whats-new {
    height: 330px;
  }

  .left-mock-req {
    height: calc(100vh - 80px);
  }

  .tl-shipment-screen {
    width: calc(100% - 290px);

    .req-summary {
      width: 290px;
      height: calc(100vh - 100px);
      top: 80px;
    }
  }

  .team-circle {
    .img-box-met {
      margin: 0px 10px !important;
    }
  }

  .prod-cart {
    width: 300px;
    height: calc(100vh - 80px);
  }

  .left-product {
    width: 260px;
    height: calc(100vh - 80px);

    .cat-title {
      height: 60px;

      p {
        font-size: 18px;
      }
    }
  }

  .withcart {
    width: calc(100% - 500px);
  }

  .withoutcart {
    width: calc(100% - 300px);
  }

  .rit-prod {
    height: calc(100vh - 80px);
    padding: 0px 7px 10px;
  }

  .pack-title {
    font-size: 21px;
  }

  .rit-send-merch {
    width: 33.33% !important;

    .content-box {
      width: 94%;
      margin-left: 3%;
      padding: 30px 10px;
    }
  }

  .prod-list-box-tl {
    // width: 27.33%;
    width: 25%;

    .prod-list-box {
      .btm-prod {
        h3 {
          font-size: 15px;
        }

        p {
          font-size: 16px;
        }

        button {
          width: 23px;
          height: 23px;
        }
      }
    }
  }

  .bac-btm-img {
    width: 115% !important;
    margin: auto;
  }

  .send-merch .left-send-merch {
    width: 60% !important;
  }

  .left-img {
    .support-img {
      width: 140px;
      margin-right: 20px;
    }

    p {
      width: 650px;
    }

    .support-cont-home {
      h4 {
        font-size: 20px;
      }

      img {
        width: 33px;
      }
    }
  }

  .sign-up .rit-auth .form-box {
    width: 470px;
    // height: 90vh;
    overflow: scroll;
  }

  .tl-log-upload-box {
    label {
      height: 270px;
    }
  }

  .tableCellStyle {
    img {
      width: 55px;
      height: 55px;
    }
  }
}

//1200-1399-end
//
//
//
//
//
//1400-start
@media (min-width: 1400px) {
  .filter-product {
    input::placeholder {
      color: $primary1;
      font-size: 18px;
      font-family: "f2";
    }

    .search-icon {
      img {
        width: 27px !important;
      }
    }
  }

  .dash-board-banner,
  .whats-new {
    height: 350px;
  }

  .team-circle {
    .img-box-met1 {
      width: 250px;
      height: 250px;
      margin: 20px 90px;
    }

    .img-box-met {
      width: 230px;
      height: 230px;
      border-radius: 150px;
      overflow: hidden;
      border: 10px solid #fff;
      margin: 20px 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .forgot-auth-rt {
    .for-email-icon {
      width: 100px;
    }
  }

  .rit-pack-sample {
    width: 330px !important;
  }

  .visit-help {
    width: 350px;
    padding: 15px 25px;

    img {
      width: 130px;
    }
  }



  .question-ans {
    .faq-img {
      width: 40%;
      padding-right: 20px;
    }

    .left-quest {
      .search-faq {
        margin-bottom: 30px;
      }
    }
  }

  .faq-banner {
    height: 500px;
    background: url("../../img/FAQuser\ 2.png") no-repeat;
    background-size: 100% 100%;
  }

  .tool-banner {
    height: 500px;
    background: url("../../img/faq_back.png") no-repeat;
    background-size: 100% 100%;
  }

  .sus-banner {
    height: 500px;
    background: url("../../img/sustainability.png") no-repeat;
    background-size: 100% 100%;
  }

  .blog-enquiry {
    .blog-prod {
      width: 300px;
    }
  }

  .prod-list-box-tl {
    width: 24.33%;

    .prod-list-box-tl .prod-list-box {
      padding: 40px;
      border-radius: 35px;
    }
  }

  // redeem start

  // redeem end

  .prod-list-login {
    width: 25%;

    .prod-list-box-tl .prod-list-box {
      padding: 40px;
      border-radius: 35px;
    }
  }

  .join-abt {
    .rit-img {
      width: 350px;
    }
  }

  .top-header {
    margin: auto;

    // height: 125px;
    // width: 89%;
    .logo {
      h3 {
        font-size: 35px !important;
      }
    }

    .menu-item {

      a,
      .menu-head-btn {
        padding: 16px 35px;
      }

      .drop-list {
        h5 {
          font-size: 17px !important;
          margin-bottom: 3px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .sign-drop-list {
      h5 {
        font-size: 17px !important;
        margin-bottom: 3px;
      }
    }
  }

  .modal-popup {
    .center-content {
      width: 1000px;
      top: 7vh;
      height: 80vh;

      .left-popup {

        // width: 580px;
        // height: 100%;
        .imges {
          // width: 390px;
          // height: 390px;
        }
      }
    }
  }

  .demo-start {
    a {
      padding: 1px 13px !important;
      height: 53px;
      width: 160px;
      border: 3px solid #07679c !important;

      &:first-child {
        margin-right: 20px !important;
      }
    }
  }

  .home-banner {
    margin-top: 0px;
  }

  .banner-left {
    h4 {
      font-size: 35px !important;
      line-height: 60px;
    }

    p {
      font-size: 23px;
      line-height: 35px;
      margin-top: 10px;
      // text-align: justify;
    }
  }

  .banner-rit {
    width: 70%;

    img {
      width: 100%;
    }
  }

  .rit-swag {
    .swag-box {

      // width: 400px;
      h5 {
        // font-size: 16px;
      }

      img {
        width: 30px;
      }

      h3 {
        // font-size: 22px;
      }

      p {
        // font-size: 15px;
      }
    }
  }

  .get-start-btn {
    width: 190px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 37px;
    color: #fff;
    font-size: 17px;
    font-family: "f3";
    background: #07679c;
  }

  .get-start-btn1 {
    width: 220px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #fff;
    font-size: 17px;
    font-family: "f3";
    background: #07679c;
    margin: auto 0 auto auto;
  }

  .serving-for {
    h3 {
      font-size: 44px;
      line-height: 60px;
    }

    p {
      font-size: 18px;
      line-height: 35px;
      margin-top: 20px;
    }
  }

  .object-fit-cover1 {
    width: 80%;
    margin: 100px auto;
    display: flex;
  }

  .improsive-feature {
    width: 84%;
    margin: 0 auto;

    .left-improsive-feature {
      width: 50%;

      h4 {
        font-size: 44px;
        width: 520px;
        line-height: 60px;
      }

      img {
        width: 24px;
        height: 24px;
      }

      p {
        font-size: 19px;
        line-height: 30px;
      }
    }

    .rit-improsive-feature {
      width: 50%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .contain {
    width: 87%;
    margin-left: auto;
    margin-right: auto;
  }

  .box-merch {
    width: 90%;
    margin: 50px 30px 50px !important;
  }

  .send-merch {
    width: 85%;

    .left-send-merch {
      width: 62%;
      margin: 10px auto 50px;

      h4 {
        font-size: 44px;
        line-height: 60px;
      }

      img {
        width: 24px;
        height: 24px;
      }

      p {
        font-size: 19px;
        line-height: 35px;
      }
    }
  }

  .rit-send-merch {
    width: 33.33% !important;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px !important;
  }

  .content-box {
    font-size: 15px !important;
    width: 90%;
    padding: 40px 10px;

    h5 {
      font-size: 22px !important;
      width: 75% !important;
    }

    img {
      width: 100px;
      margin-bottom: 40px;
    }

    p {
      font-size: 18px !important;
      font-family: "f2";
      line-height: 32px !important;
    }
  }

  .sect-title {
    font-size: 40px;
    line-height: 60px;
  }

  .parag {
    font-size: 18px !important;
    line-height: 32px !important;
  }

  .parag1 {
    font-size: 17px !important;
    line-height: 32px !important;
  }

  .home-support {
    .left-img {
      margin-left: 10px;

      p {
        padding-right: 30px;
      }

      .support-img {
        width: 300px;
        margin-right: 20px;
      }
    }
  }

  .support-cont-home {
    h4 {
      font-size: 25px;
    }
  }

  .tl-pack {
    .list-pack {
      svg {
        font-size: 22px;
      }
    }
  }

  .home-feed-back {
    margin-bottom: 40px;

    .box {
      width: 60%;
      margin-bottom: 0px;
      margin-top: -20px;
      margin-left: -20px;
    }

    .left-box {
      width: 150px;
      height: 150px;
    }

    .rit-box {
      width: 70%;
      margin-left: 15px;
    }
  }

  .footer {
    .logos {
      width: 350px;
    }

    .rit-ftr {
      width: calc(100% - 350px);
    }
  }

  .sign-up {
    height: 100vh;

    .left-auth {
      margin-top: 0px;
    }

    .rit-auth {
      .form-box {
        overflow-x: hidden;
        width: 500px !important;
      }
    }

    .form-box1 {
      height: 90vh;
    }
  }

  .left-mock-req {
    height: calc(100vh - 125px);
  }

  .tl-shipment-screen {
    width: calc(100% - 290px);

    .req-summary {
      width: 290px;
      height: calc(100vh - 130px);
      top: 115px;
    }
  }

  .prod-cart {
    // width: 1250px;
    width: 420px;
    height: calc(100vh - 125px);

    h4 {
      font-size: 20px;
    }

    .list-cart {
      height: calc(100% - 250px);

      .prod-cart-list {
        .img-box {
          width: 60px;
          height: 60px;
          border-radius: 3px;
          margin-right: 5px;
        }

        h5 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
        }

        h6 {
          font-size: 14px;
        }

        button {
          width: 30px;
          height: 30px;
          margin-left: 20px;

          svg {
            font-size: 25px;
          }
        }
      }
    }

    .btm-cart {
      height: 200px;

      h5 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }

      h6 {
        font-size: 16px;
      }
    }
  }

  .left-product {
    width: 380px;
    height: calc(100vh - 125px);

    .cat-title {
      height: 60px;

      p {
        font-size: 21px;
      }
    }
  }

  .withcart {
    width: calc(100% - 600px);
  }

  .withoutcart {
    width: calc(100% - 200px);
  }

  .rit-prod {
    height: calc(100vh - 125px);
    padding: 0px 0px 10px;
  }

  .rit-pop {
    width: 85%;

    h2 {
      font-size: 35px !important;
    }

    h3 {
      font-size: 21px !important;
    }

    .color-box {
      width: 30px !important;
      height: 30px !important;
      margin-right: 15px !important;
    }

    .qunt-btn {
      font-size: 33px;
    }
  }

  .tl-log-upload-box {
    label {
      height: 230px;
    }
  }

  .abt-hq .box-mis-vis img {
    width: 100px !important;
  }

  .how-work {
    img {
      width: 150px;
    }

    h5 {
      width: 30px;
      height: 30px;
    }
  }

  .let-start-box {
    img {
      width: 180px;
    }
  }

  .img-box-tee {
    width: 90px;
  }

  .img-box-tee_new {
    width: 140px;
  }

  .upload1 {
    width: 65px;
    height: 65px;
  }

  .address-box button {
    border-style: none;
    background: none;
    color: #07679c;
    font-size: 20px;
    font-family: "f3";
  }
}

//1400-end
@media (min-width: 1600px) {
  .banner-left {
    h4 {
      font-size: 40px !important;
      line-height: 60px;
    }
  }

  .team-circle {
    .img-box-met1 {
      width: 300px;
      height: 300px;
      margin: 20px 90px;
    }

    .img-box-met {
      width: 250px;
      height: 250px;
      border-radius: 150px;
      overflow: hidden;
      border: 10px solid #fff;
      margin: 20px 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .question-ans {
    .faq-img {
      width: 30%;
      padding-right: 20px;
    }
  }

  p {
    font-family: "f2";
    font-size: 18px;
    margin-bottom: 0;
  }

  .rit-pop {
    .color-box {
      width: 35px !important;
      height: 35px !important;
    }
  }

  .bread-crumb {

    a,
    svg {
      font-size: 20px;
    }
  }

  .product-list-detail .box-shadow-product_view {
    padding: 10px 30px 20px;
    width: 420px !important;
  }

  .product-list-detail .box-shadow-product {
    padding: 10px 30px 20px;
    width: 420px !important;
  }

  .delivery_product {
    img {
      width: 50px;
    }

    h6 {
      font-size: 18px;
    }

    p {
      font-size: 15px;
      margin-bottom: 0;
    }
  }

  .tl-log-upload-box {
    label {
      height: 350px;
    }
  }

  .join-abt {
    .rit-img {
      width: 500px;
    }
  }

  .blog-enquiry {
    .blog-prod {
      width: 400px;
    }
  }

  .left-product {
    width: 360px;
    height: calc(100vh - 125px);

    .cat-title {
      height: 60px;
      padding-left: 8px !important;

      p {
        font-size: 21px;
      }
    }
  }

  .dash-board-banner {
    img {
      width: 48% !important;
    }
  }
}