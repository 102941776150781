.as {
  align-items: start;
}

.ac {
  align-items: center;
}

.ae {
  align-items: end;
}

.afs {
  align-items: flex-start;
}

.afe {
  align-items: flex-end;
}

.js {
  justify-content: start;
}

.jc {
  justify-content: center;
}

.je {
  justify-content: end;
}

.jsa {
  justify-content: space-around;
}

.jsb {
  justify-content: space-between;
}

.jse {
  justify-content: space-evenly;
}
.df {
  display: flex;
}
.fc {
  flex-direction: column;
}
.fr {
  flex-direction: row;
}

.fd-cr {
  flex-direction: column-reverse;
}
.fr-rr {
  flex-direction: row-reverse;
}

@media (max-width: 576px) {
  .as-xs {
    align-items: start;
  }

  .ac-xs {
    align-items: center;
  }

  .ae-xs {
    align-items: end;
  }

  .afs-xs {
    align-items: flex-start;
  }

  .afe-xs {
    align-items: flex-end;
  }

  .js-xs {
    justify-content: start;
  }

  .jc-xs {
    justify-content: center;
  }

  .je-xs {
    justify-content: end;
  }

  .jsa-xs {
    justify-content: space-around;
  }

  .jsb-xs {
    justify-content: space-between;
  }

  .jse-xs {
    justify-content: space-evenly;
  }
  .fd-cr-xs {
    flex-direction: column-reverse;
  }
  .fr-rr-xs {
    flex-direction: row-reverse;
  }
}
//sm
@media (min-width: 577px) {
  .as-sm {
    align-items: start;
  }

  .ac-sm {
    align-items: center;
  }

  .ae-sm {
    align-items: end;
  }

  .afs-sm {
    align-items: flex-start;
  }

  .afe-sm {
    align-items: flex-end;
  }

  .js-sm {
    justify-content: start;
  }

  .jc-sm {
    justify-content: center;
  }

  .je-sm {
    justify-content: end;
  }

  .jsa-sm {
    justify-content: space-around;
  }

  .jsb-sm {
    justify-content: space-between;
  }

  .jse-sm {
    justify-content: space-evenly;
  }
  .df-sm {
    display: flex;
  }
  .fc-sm {
    flex-direction: column;
  }
  .fr-sm {
    flex-direction: row;
  }
  .fd-cr-sm {
    flex-direction: column-reverse;
  }
  .fr-rr-sm {
    flex-direction: row-reverse;
  }
}
//md
@media (min-width: 768px) {
  .as-md {
    align-items: start;
  }

  .ac-md {
    align-items: center;
  }

  .ae-md {
    align-items: end;
  }

  .afs-md {
    align-items: flex-start;
  }

  .afe-md {
    align-items: flex-end;
  }

  .js-md {
    justify-content: start;
  }

  .jc-md {
    justify-content: center;
  }

  .je-md {
    justify-content: end;
  }

  .jsa-md {
    justify-content: space-around;
  }

  .jsb-md {
    justify-content: space-between;
  }

  .jse-md {
    justify-content: space-evenly;
  }
  .df-md {
    display: flex;
  }
  .fc-md {
    flex-direction: column;
  }
  .fr-md {
    flex-direction: row;
  }
  .fd-cr-md {
    flex-direction: column-reverse;
  }
  .fr-rr-md {
    flex-direction: row-reverse;
  }
}
//lg
@media (min-width: 992px) {
  .as-lg {
    align-items: start;
  }

  .ac-lg {
    align-items: center;
  }

  .ae-lg {
    align-items: end;
  }

  .afs-lg {
    align-items: flex-start;
  }

  .afe-lg {
    align-items: flex-end;
  }

  .js-lg {
    justify-content: start;
  }

  .jc-lg {
    justify-content: center;
  }

  .je-lg {
    justify-content: end;
  }

  .jsa-lg {
    justify-content: space-around;
  }

  .jsb-lg {
    justify-content: space-between;
  }

  .jse-lg {
    justify-content: space-evenly;
  }
  .df-lg {
    display: flex;
  }
  .fc-lg {
    flex-direction: column;
  }
  .fr-lg {
    flex-direction: row;
  }
  .fd-cr-lg {
    flex-direction: column-reverse;
  }
  .fr-rr-lg {
    flex-direction: row-reverse;
  }
}
//xl
@media (min-width: 1200px) {
  .as-xl {
    align-items: start;
  }

  .ac-xl {
    align-items: center;
  }

  .ae-xl {
    align-items: end;
  }

  .afs-xl {
    align-items: flex-start;
  }

  .afe-xl {
    align-items: flex-end;
  }

  .js-xl {
    justify-content: start;
  }

  .jc-xl {
    justify-content: center;
  }

  .je-xl {
    justify-content: end;
  }

  .jsa-xl {
    justify-content: space-around;
  }

  .jsb-xl {
    justify-content: space-between;
  }

  .jse-xl {
    justify-content: space-evenly;
  }
  .df-xl {
    display: flex;
  }
  .fc-xl {
    flex-direction: column;
  }
  .fr-xl {
    flex-direction: row;
  }
  .fd-cr-xl {
    flex-direction: column-reverse;
  }
  .fr-rr-xl {
    flex-direction: row-reverse;
  }
}
//xxl
@media (min-width: 1400px) {
  .as-xxl {
    align-items: start;
  }

  .ac-xxl {
    align-items: center;
  }

  .ae-xxl {
    align-items: end;
  }

  .afs-xxl {
    align-items: flex-start;
  }

  .afe-xxl {
    align-items: flex-end;
  }

  .js-xxl {
    justify-content: start;
  }

  .jc-xxl {
    justify-content: center;
  }

  .je-xxl {
    justify-content: end;
  }

  .jsa-xxl {
    justify-content: space-around;
  }

  .jsb-xxl {
    justify-content: space-between;
  }

  .jse-xxl {
    justify-content: space-evenly;
  }
  .df-xxl {
    display: flex;
  }
  .fc-xxl {
    flex-direction: column;
  }
  .fr-xxl {
    flex-direction: row;
  }
  .fd-cr-xxl {
    flex-direction: column-reverse;
  }
  .fr-rr-xxl {
    flex-direction: row-reverse;
  }
}
