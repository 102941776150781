@import "../constatnt/fonts.scss";
@import "../constatnt/flex.scss";
@import "../constatnt/color.scss";
@import "../constatnt/border.scss";
@import "../template/color.scss";

body,
html {
  font-family: "f1";
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
}

// body {
// background-color: $lt-blue2 !important;
// background-color: #fdfcfc;
// background-color: #fff;
// }

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(230, 230, 230);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
}

.out-left {
  z-index: 0;
}

.delivery-box {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 11px 11px 29px -1px #bacff0;
  -webkit-box-shadow: 3px 6px 10px 0px #bacff0;
}

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-next-icon {
  display: none !important;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -18px !important;
  left: 0;
}

.menu-a-list {
  color: $primary !important;
}

.pointer {
  cursor: pointer !important;
}

.top-header {
  width: 70%;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  z-index: 1000 !important;

  .sign-btn {
    width: 125px;
    height: 50px;
    border-radius: 13px;
    align-items: center;
    background: $lt-blue1;
    justify-content: space-between;
    display: flex;
    padding: 0 30px;

    svg {
      width: 32px;
      height: 32px;
    }

    .user-icon {
      width: 32px;
      height: 32px;
      margin-left: 10px;
    }
  }

  .sign-drop-list {
    position: absolute;
    right: 0px;
    top: 44px;
    background: #f1f7ff;
    border: 3px solid #d7dff1;
    box-shadow: 6px 6px 25px -13px #7787ac;
    -webkit-box-shadow: 6px 6px 25px -13px #7787ac;
    padding: 10px 10px;
    border-radius: 20px;
    width: 200px;
    z-index: 1;

    button {
      width: 100%;

      h5 {
        color: $black;
        font-size: 16px;
        font-family: "f3";
      }
    }
  }

  .logo {
    width: 170px;
  }

  .object-fit-cover1 {
    width: 90%;
    margin: 30px auto;
  }

  .overflow-scroll {
    overflow: scroll !important;
  }

  .menu-item {
    a,
    .menu-head-btn {
      color: $black;
      font-family: "f3";
    }

    .active {
      width: 60px;
      height: 3px;
      background: $primary1;
      position: absolute;
      bottom: -5px;
      left: calc(50% - 30px);
      border-radius: 10px;
    }

    .drop-list {
      left: -40px;
      top: 48px;
      background: #fff;
      border: 3px solid #d7dff1;
      box-shadow: 6px 6px 25px -13px #7787ac;
      -webkit-box-shadow: 6px 6px 25px -13px #7787ac;
      padding: 20px 10px;
      border-radius: 20px;
      width: 245px;
      z-index: 11111;

      .left-imgs {
        width: 56px;
        height: 56px;
        border-radius: 7px;
        background: #e9f0ff;
        margin-right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 80%;
          height: auto;
        }
      }

      .dorp-rit-cont {
        width: 150px;

        h5 {
          color: $black;
          font-size: 16px;
          font-family: "f3";
        }

        p {
          color: $primary1;
          font-size: 14px;
          font-family: "f2";
          margin-bottom: 0;
        }
      }
    }
  }
}

.main-logo {
  width: 160px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.menu-icon {
  width: 40px;
  height: 37px;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  transition: 0.2s all linear;
  background: $lt-blue1;
  border-radius: 7px;
  border: 0;

  svg {
    font-size: 20px;
  }
}

.demo-start {
  display: flex;

  a {
    border: 3px solid $primary;
    padding: 0 7px;
    height: 36px;
    border-radius: 10px;
  }
}

.home-banner {
  width: 70%;
  margin-left: 15% !important;
  // padding: 34px 6% 1% 3.8%;
  background-color: "#fff";

  .banner-left {
    padding: 20px;

    h4 {
      font-family: "f4";
      color: #454545;
      margin-bottom: 3px;
    }
  }
}

.serving-for {
  h3 {
    color: $gray1;
    font-family: "f4";
    margin-top: 70px;
  }

  p {
    line-height: 35px;
    // margin-top: 20px;
    font-family: "f2";
    color: $primary1;
  }

  .logos {
    width: 70%;
    margin: 0px auto 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 25px;
    background: #ffffff;
    box-shadow: 4px 4px 11px 2px #e2ebff;

    -webkit-box-shadow: 4px 4px 11px 2px #e2ebff;

    img {
      height: 70px;
      width: 100px !important;
      margin: 10px auto;
      object-fit: contain;
    }
  }
}

.logos3 {
  width: 83%;
  margin: 10px auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 25px 15px;
  border-radius: 25px;
  background: #ffffff;
  // box-shadow: inset 1px 7px 8px 2px #fff, inset -5px 0px 26px 7px #d8eaf7,
  //   0 0px 2px -1px #dce3eb;

  // -webkit-box-shadow: inset 1px 7px 8px 2px #fff,
  //   inset -5px 0px 26px 7px #d8eaf7, 0 0px 2px -1px #dce3eb;
  .owl-item {
    margin: auto 20px;
    flex-wrap: nowrap;
  }

  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
    height: 200px;
  }

  img {
    // width: 80% !important;
    margin: 10px auto;
    height: 200px;
    width: 200px;
    object-fit: cover;
  }

  .log {
    bottom {
      margin-left: 20px !important;
    }

    .slisd {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 150px;

      p {
        color: #454545;
        font-family: "f4";
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 17px;
      }
    }
  }
}

.border-curv-img {
  border-radius: 30px;
  overflow: hidden;

  img {
    width: 80%;
  }

  // background: #000;
}

.improsive-feature {
  display: flex;
  align-items: center;
  justify-content: center;

  .left-improsive-feature {
    width: 50%;

    h4 {
      color: $gray1;
      font-family: "f4";
    }

    img {
      margin-right: 8px;
    }

    p {
      color: $gray1;
      font-family: "f2";
      margin-top: -5px;
    }
  }

  .rit-improsive-feature {
    width: 80%;
    margin-right: 50px;
    border-radius: 40px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

.send-merch {
  width: 83%;
  margin: 10px auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .left-send-merch {
    width: 50%;

    h4 {
      color: $gray1;
      font-family: "f4";
    }

    img {
      margin-right: 8px;
    }

    p {
      color: $primary1;
      font-family: "f2";
      margin-top: 10px;
    }
  }
}

.rit-send-merch {
  position: relative;
  margin-bottom: 30px;

  h4 {
    color: $gray1;
    font-family: "f4";
  }

  .back-img {
    width: 94%;
  }

  .content-box {
    height: 255px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background: #f4f8ff;
    box-shadow: inset -20px 20px 20px 0px #c7d5ef4d, 5px -5px 10px 0px #c7d5ef33,
      -5px 5px 0px 0px #ffffff inset;
    -webkit-box-shadow: -20px 20px 20px 0px #c7d5ef4d,
      5px -5px 10px 0px #c7d5ef33, -5px 5px 0px 0px #ffffff inset;
    border-radius: 20px;

    h5 {
      color: $gray1;
      font-family: "f4";
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 18px;
      width: 91%;
    }

    img {
      width: 70px;
      margin-bottom: 20px;
    }

    p {
      width: 96%;
      font-family: "f2";
      color: $primary1;
      font-size: 15px;
    }
  }
}

.box-merch {
  margin: 30px auto 50px;
}

.sect-title {
  color: $gray1;
  font-family: "f4";
}

.parag {
  font-family: "f2";
  color: $primary1;
  font-size: 15px;
}

.parag1 {
  font-family: "f1";
  font-size: 14px;
}

.home-support {
  // background: #f1f7fc;
  box-shadow: inset 5px 7px 10px 5px #fff, inset 0px 3px 11px 7px #d8d8e7,
    5px 5px 10px 0px #eef1fd;
  -webkit-box-shadow: inset 5px 7px 10px 5px #fff,
    inset 0px 3px 11px 7px #d8d8e7, 5px 5px 10px 0px #eef1fd;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 30px 10px;
}

.simple-pack {
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 70px;
  margin-top: 70px;
  padding: 30px 10px;
}

.simple-pack1 {
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 30px 10px;

  .pac-img1 {
    width: 200px;
  }
}

.pac-img1 {
  width: 200px;
}

.contain {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.left-img {
  border-right: 2px solid $gray3;

  p {
    width: 600px;
  }

  .support-img {
    width: 200px;
  }
}

.left-img2 {
  p {
    width: 600px;
  }

  .support-img {
    width: 200px;
  }
}

.support-cont-home {
  position: relative;

  img {
    width: 45px;
  }

  h4 {
    font-family: "f5";
    color: $primary;
    margin-bottom: 0;
  }
}

.box-merch2 {
  .rit-send-merch {
    margin-top: -30px;
  }
}

.box-merch3 {
  .rit-send-merch {
    margin-top: -30px;
  }
}

.owl-kits {
  width: 160.8px !important;
  height: 200px !important;
}

.owl-products {
  width: 95%;
  min-height: 350px;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  object-fit: contain !important;
  // height: 200px !important;
}

.product-caro-img {
  max-height: 200px;
}
.carousel-indicators [data-bs-target] {
  background-color: #000 !important;
}

.home-should {
  background: $lt-orange;
  padding: 50px 20px;
}

.way_should {
  background: $lt-orange;
  padding: 50px 20px;
}

.rit-swag {
  .swag-box {
    background: $white;
    border-radius: 30px;
    padding: 50px 50px;
    margin: 12px;
    width: 45%;

    .btn-merch {
      border-radius: 10px;
      padding: 10px 13px;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 30px;
      background: #e8e8e8;

      h5 {
        font-size: 18px;
        font-family: "f4";
        margin-bottom: 0;
      }

      img {
        margin-left: 10px;
      }
    }

    .btn-merchh {
      border-radius: 10px;
      box-shadow: inset 0px 0px 23px #7ea4ce;
      -webkit-box-shadow: inset 0px 0px 23px #7ea4ce;

      padding: 10px 13px;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      h5 {
        font-size: 18px;
        font-family: "f4";
        margin-bottom: 0;
      }

      img {
        margin-left: 10px;
      }
    }

    h3 {
      font-size: 28px;
      margin-top: 20px;
      font-family: "f4";
      margin-bottom: 0;
      color: $gray1;
      text-align: center;
    }

    .list-warn {
      svg {
        font-size: 25px;
        margin-top: 3px;
        color: $sec;
      }

      img {
        width: 35px;
        height: 29px;
        margin-top: 16px;
      }

      p {
        font-size: 19px;
        font-family: "f2";
        margin-left: 8px;
        color: $primary1;
        line-height: 20px;
        margin-bottom: 0px;
        margin-top: 16px;
      }

      // .cent-button{
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }
    }
  }
}

.btn-build {
  border-radius: 10px;
  border: 0px solid $primary;
  padding: 13px 13px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  h5 {
    font-size: 17px;
    font-family: "f3";
    margin-bottom: 0;
  }

  img {
    margin-right: 5px;
  }
}

.tl-pack {
  background-image: url("../../img/back3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  padding: 70px 10px 50px 0px;
  margin-top: -20px;

  .list-pack {
    svg {
      margin-top: 4px;
      margin-right: 10px;
      color: $green1;
    }
  }
}

.group-kits {
  width: 200px;
}

.pac-img {
  width: 80%;
}

.home-feed-back {
  .box {
    width: 50%;
    padding: 1.5%;

    .box1 {
      border-radius: 30px;
      // background: $lt-blue;
      box-shadow: 0px 5px 14px 2px #e2ebff;
      -webkit-box-shadow: 0px 5px 14px 2px #e2ebff;
      margin-bottom: 20px;
      padding: 30px;
      // width: 100%;
      height: 286px;

      .left-box {
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .txt-btm {
    .left-btm {
      margin-top: 5px;

      h5 {
        // color: $gray1;
        margin-bottom: 7px;
        font-family: "f4";
      }

      p {
        color: $primary1;
        margin-bottom: 0;
        font-family: "f2";
        font-size: 16px;
      }

      img {
        width: 120px;
        margin-top: 5px;
      }
    }
  }
}

.tab-pack {
  width: 580px;
  align-self: center;
}

.home-tab {
  .pac-box {
    width: 30%;
    margin-top: 10px;
    // padding: 0 3%;

    .pac-box1 {
      border-radius: 30px;
      background: #fff;
      box-shadow: inset -2px 2px 4px 3px #fff,
        10px 10px 13px -5px rgba(161, 183, 219, 0.44);
      -webkit-box-shadow: inset -2px 2px 4px 3px #fff,
        11px 16px 19px 5px rgba(161, 183, 219, 0.44);
      margin-bottom: 20px;
      padding: 30px 10px;

      .top-img {
        width: 70%;
        margin: auto;

        .img1 {
          width: 100%;
        }
      }

      .echo-img {
        width: 70% !important;
        margin: auto !important;

        .echo {
          width: 100% !important;
        }
      }

      .logist {
        width: 20px;
        height: 20px;
      }

      h5 {
        font-size: 24px;
        margin-top: 7px;
        color: #000;
        font-family: "f4";
      }
    }
  }
}

.logists {
  width: 20px;
  height: 20px;
}

.footer {
  background: $lt-blue;
  z-index: 0;
  position: relative;

  h3 {
    font-size: 33px;
    font-family: "f4";
  }

  .social-ftr-lnk {
    margin-left: -10px;
  }

  img {
    width: 100px;
  }

  .social-footer-icon {
    background: #fff;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    box-shadow: inset -1px -2px 5px 3px #cadbf1, 2px 2px 6px 0px #e2ebff,
      inset 2px 2px 6px 0px #f6f8ff;
    -webkit-box-shadow: inset -1px -2px 5px 3px #cadbf1, 2px 2px 6px 0px #e2ebff,
      inset 2px 2px 6px 0px #f6f8ff;
    border-radius: 5px;
    margin: 10px;

    img {
      width: 30px;
      height: 30px;
      padding: 5px;
    }
  }

  .logos {
    width: 260px;
  }
}

.ftr-list {
  width: 25%;
  margin-bottom: 20px;

  h5 {
    font-size: 23px;
    font-family: "f4";
  }

  a {
    display: inherit;
    font-size: px;
    font-family: "f2";
  }
}

.rit-ftr {
  width: calc(100% - 260px);
}

.ftr-btm {
  border-top: 2px solid $orange;
  padding: 10px;
  width: 80%;
}

.forgot-auth-rt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .for-email-icon {
    width: 70px;
    margin: 20px auto;
    display: flex;
  }
}

.login-btn {
  height: 40px;
}

.sign-up {
  padding: 30px 0;

  padding: 30px;

  .left-auth {
    width: 40%;
    display: flex;
    align-items: flex-end;

    img {
      width: 80%;
    }
  }

  .log-logo {
    position: absolute;
    left: 82px;
    top: 40px;
  }

  .bg1 {
    width: 190px;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 10%;
  }

  .bg2 {
    width: 230px;
    position: fixed;
    z-index: -1;
    transform: rotateY(180deg);
    bottom: -100px;
    right: 0%;
  }
}

.rit-auth {
  .form-box {
    width: 400px;
    padding: 30px 30px;
    border-radius: 15px;
    background: #f3f7ff;
    box-shadow: 9px 8px 11px -2px #dfe8f8, inset 12px 12px 6px -4px #ffffff,
      inset -12px -12px 12px -4px #d7deef;
    -webkit-box-shadow: 9px 8px 11px -2px #dfe8f8,
      inset 12px 12px 6px -4px #ffffff, inset -12px -12px 12px -4px #d7deef;

    h5 {
      font-size: 31px;
      color: $gray1;
      font-family: "f4";
    }
  }

  .box {
    margin-top: 10px;
    padding: 10px;
    width: 100%;

    p {
      font-size: 19px;
      color: $primary1;
      font-family: "f2";
      margin-bottom: 5px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }
  }
}

.preset-kit-box {
  .topImg {
    width: 70%;
    margin: auto;
  }
}

.react-tel-input .form-control:focus {
  box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff !important;
  -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
    inset -5px -5px 9px 1px #fff !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 250px) !important;
}

.input-box {
  margin-top: 10px;
  padding: 10px;
  width: 100%;

  p {
    font-size: 17px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  .react-tel-input .flag-dropdown {
    border: 0 !important;
    width: 42px !important;
  }

  .selected-flag {
    font-size: 18px !important;
    font-family: f2;
    width: 100% !important;
    height: 53px !important;
    background: #f3f7ff !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border: 0 !important;
    padding: 0 10px !important;
    box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
  }

  input {
    font-size: 18px !important;
    font-family: f2;
    width: 100% !important;
    height: 53px !important;
    background: #f3f7ff !important;
    border-radius: 10px;
    border: 0 !important;
    padding: 0 20px;
    box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
  }
}

.empty-date {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 190px;
  height: 30px;
  background: #f3f7ff;
}

.btn-light {
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-active-bg: #fff !important;
}

.left-product {
  width: 262px;
  overflow-y: scroll;
  z-index: 111;

  .cat-title {
    height: 45px;
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    background: $lt-blue;

    svg {
      width: 30px;
      height: 30px;
    }

    p {
      margin-bottom: 0;
      font-family: "f4";
      font-size: 18px;
      color: $gray1;
      margin-left: 17px;
    }
  }

  .list-box {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 18px 5px;

    p {
      font-size: 16px;
      text-align: left;
    }

    .img-back {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
      -webkit-box-shadow: -5px 5px 5px -2px #d1e0f8,
        inset -5px 5px 4px -3px #dbe5f8;
      background: #f4f8ff;

      img {
        width: 47%;
        height: 47%;
      }
    }

    .active {
      width: 42px;
      height: 42px;
      border-radius: 100px;
      box-shadow: 5px 5px 5px -1px #abc6f1;
      -webkit-box-shadow: 5px 5px 5px -1px #abc6f1;
      background: linear-gradient(#0692df, #02456b 90%);

      img {
        width: 47%;
        height: 47%;
        filter: invert(1) grayscale(12) contrast(5);
      }
    }
  }
}

.cat-load-img-box {
  width: 100%;
  height: 200px;
  background: #c7d9e7;
}

.rit-prod {
  margin: 0 auto;
  overflow: scroll;
}

.prod-cart {
  overflow: scroll;
  border-radius: 10px 0 0 10px;
  border: 2px solid $gray3;
  padding: 10px 10px 0;
  transition: 0.2s all linear;

  h4 {
    color: #000;
    font-family: "f4";
    margin-bottom: 15px;
    font-size: 17px;
  }

  .bulk-list-cart {
    overflow-y: scroll;
    height: calc(100% - 180px);

    .prod-cart-list {
      margin-bottom: 10px;

      .img-box {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;

        img {
          width: 100%;
        }
      }

      h5 {
        font-family: "f4";
        color: #000;
        margin-bottom: 0;
        display: inline-block;
        width: 100px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      p {
        font-family: "f1";
        color: $primary1;
        font-size: 12px;
        margin-bottom: 0;
      }

      h6 {
        font-family: "f2";
        color: #000;
        margin-top: 3px;
        font-size: 12px;
      }

      button {
        width: 30px;
        height: 30px;

        svg {
          color: $primary1;
          font-size: 23px;
          width: 100%;
        }
      }
    }
  }

  .build-list-cart {
    overflow-y: scroll;
    height: calc(100% - 210px);

    .prod-cart-list {
      margin-bottom: 10px;

      .img-box {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        margin-right: 10px;

        img {
          width: 100%;
        }
      }

      h5 {
        font-family: "f4";
        color: #000;
        margin-bottom: 0;
        display: inline-block;
        width: 100px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      p {
        font-family: "f1";
        color: $primary1;
        font-size: 12px;
        margin-bottom: 0;
      }

      h6 {
        font-family: "f2";
        color: #000;
        margin-top: 3px;
        font-size: 12px;
      }

      button {
        width: 30px;
        height: 30px;

        svg {
          color: $primary1;
          font-size: 23px;
          width: 100%;
        }
      }
    }
  }

  .list-cart1 {
    overflow-y: scroll;
    height: calc(100% - 180px);

    .prod-cart-list {
      margin-bottom: 10px;

      .img-box {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;

        img {
          width: 100%;
        }
      }

      h5 {
        font-family: "f4";
        color: #000;
        margin-bottom: 0;
        display: inline-block;
        width: 100px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      p {
        font-family: "f1";
        color: $primary1;
        font-size: 12px;
        margin-bottom: 0;
      }

      h6 {
        font-family: "f2";
        color: #000;
        margin-top: 3px;
        font-size: 12px;
      }

      button {
        width: 30px;
        height: 30px;

        svg {
          color: $primary1;
          font-size: 23px;
          width: 100%;
        }
      }
    }
  }

  .list-cart {
    overflow-y: scroll;
    height: calc(100% - 210px);

    .prod-cart-list {
      margin-bottom: 10px;

      .img-box {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;

        img {
          width: 100%;
        }
      }

      h5 {
        font-family: "f4";
        color: #000;
        margin-bottom: 0;
        display: inline-block;
        width: 100px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      p {
        font-family: "f1";
        color: $primary1;
        font-size: 12px;
        margin-bottom: 0;
      }

      h6 {
        font-family: "f2";
        color: #000;
        margin-top: 3px;
        font-size: 12px;
      }

      button {
        width: 30px;
        height: 30px;

        svg {
          color: $primary1;
          font-size: 23px;
          width: 100%;
        }
      }
    }
  }

  .build-btm-cart {
    position: absolute;
    width: 100%;
    // bottom: 0;
    bottom: 47px;
    height: 125px;

    // background: #ae2;
    h5 {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }

    h6 {
      font-size: 14px;
    }
  }

  .bulk-btm-cart {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 100px;

    // background: #ae2;
    h5 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }

    h6 {
      font-size: 14px;
    }
  }

  .btm-cart1 {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 130px;

    // background: #ae2;
    h5 {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }

    h6 {
      font-size: 14px;
    }
  }

  .btm-cart {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 180px;
    padding-top: 0px;

    // background: #ae2;
    h5 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }

    h6 {
      font-size: 14px;
    }
  }
}

.pack-prod {
  width: 100% !important;
  margin-left: 0 !important;
  overflow: auto !important;
  height: auto !important;
}

.pack-title {
  z-index: -111;
  font-size: 26px;
  font-family: "f4";
  color: #07679c;
}

.left-one {
  z-index: 111 !important;
}

.prod-list-box-tl {
  .prod-list-box {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    // box-shadow: 11px 11px 29px -1px #bacff0;
    -webkit-box-shadow: 11px 11px 29px -1px #bacff0;
    box-shadow: 8.42px 8.42px 16.84px 0px #c7d5efcc;

    .prod-list-box-img {
      width: 100%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .btm-prod {
      h3 {
        font-size: 15px;
        height: 40px;
        margin-top: 10px;
        color: #000;
        font-family: "f4";
      }

      p {
        font-size: 15px;
      }

      button {
        width: 25px;
        height: 25px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: auto 0 auto auto;
        color: #fff;
        font-size: 33px;
        font-family: "f5";
        background: #07679c;
      }
    }
  }
}

.prod-list-box-tl2 {
  .prod-list-box {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 11px 11px 29px -1px #bacff0;
    -webkit-box-shadow: 11px 11px 29px -1px #bacff0;
    // box-shadow: 8.42px 8.42px 16.84px 0px #C7D5EFCC;

    .prod-list-box-img {
      width: 100%;
      overflow: hidden;

      img {
        width: 60%;
      }
    }

    .btm-prod {
      h3 {
        font-size: 17px;
        margin-top: 10px;
        color: #000;
        font-family: "f4";
      }

      p {
        font-size: 16px;
      }

      button {
        width: 35px;
        height: 35px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: auto 0 auto auto;
        color: #fff;
        font-size: 33px;
        font-family: "f5";
        background: #07679c;
      }
    }
  }
}

.prod-list-login {
  .prod-list-box {
    overflow: scroll;
    // width: 175px;
    // height: 250px;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    // box-shadow: inset -2px 2px 4px 3px #fff,
    //   10px 10px 13px -5px rgba(161, 183, 219, 0.44);
    // box-shadow: 8.42px 8.42px 16.84px 0px #C7D5EFCC;
    box-shadow: 8.42px 8.42px 16.84px 0px rgba(199, 213, 239, 0.8);

    .prod-list-box-img {
      width: 100%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .btm-prod {
      h3 {
        font-size: 17px;
        margin-top: 10px;
        color: #000;
        font-family: "f4";
      }

      p {
        font-size: 16px;
      }

      .plus {
        width: 35px;
        height: 35px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: auto 0 auto auto;
        color: #fff;
        font-size: 33px;
        font-family: "f5";
        background: #07679c;
      }
    }
  }
}

.image-gallery-play-button {
  left: 0;
  display: none !important;
}

.tabblle {
  tr td {
    border: 1px solid rgb(202, 202, 202) !important;
    padding: 5px !important;
  }

  th {
    border: 1px solid rgb(202, 202, 202) !important;
    padding: 5px !important;
  }
}

.mobile-cart {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 111;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: $primary1 !important;

  svg {
    font-size: 30px;
    color: $primary;
  }
}

//
//
//
//product-popup-start
.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #fff;
    border-radius: 30px;
    position: fixed;
    top: 1vh;
    width: 86%;
    height: 98vh;
    overflow: scroll;
  }

  .center-content-contact3 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    display: grid;
    align-items: center;
    top: 15vh;
    // width: 70%;
  }

  .left-popup1 {
    height: 100%;
    background: #fff;
    // align-items: center;
    justify-content: center;
    display: flex;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;

    .imges {
      width: 100%;
      height: 80%;
      background: #fff;
      box-shadow: 0px 15px 10px -8px #d5e5fb;
      -webkit-box-shadow: 0px 15px 10px -8px #d5e5fb;
      border-radius: 30px;
      display: flex;
      align-items: center;
      margin-right: -50px;
      justify-content: center;
      overflow: hidden;

      img {
        width: 80%;
        margin: auto;
      }
    }

    .owl-dots {
      margin: -10px auto 20px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      justify-content: center;

      .owl-dot {
        width: 30px;
        height: 7px;
        border-radius: 10px;
        margin: 0 4px;
        background: $lt-blue1;
        transition: 0.5s all linear;
      }

      .active {
        background: $primary;
        width: 45px;
        transition: 0.5s all linear;
      }
    }
  }

  .left-popup {
    z-index: 1;
    width: 450px;
    height: 100%;
    background: #fff;
    // align-items: center;
    justify-content: center;
    display: flex;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    margin-left: 15px;

    .imges {
      width: 100%;
      margin-top: 15px;
      background: #fff;
      box-shadow: 3px 5px 28px 8px #d5e5fb;
      -webkit-box-shadow: 3px 5px 28px 8px #d5e5fb;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        margin: auto;
      }
    }

    .owl-dots {
      margin: -10px auto 20px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      justify-content: center;

      .owl-dot {
        width: 30px;
        height: 7px;
        border-radius: 10px;
        margin: 0 4px;
        background: $lt-blue1;
        transition: 0.5s all linear;
      }

      .active {
        background: $primary;
        width: 45px;
        transition: 0.5s all linear;
      }
    }
  }

  .rit-pop {
    padding-left: 50px;
    background: #fff;
    height: 100%;

    h2 {
      color: #000;
      font-family: "f4";
      margin-top: 30px;

      span {
        color: $primary1;
        font-family: "f2";
        font-size: 15px;
        margin-left: 10px;
      }
    }

    p {
      margin-bottom: 6px;
    }

    h3 {
      font-size: 20px;
      color: #000;
      font-family: "f4";
      margin-bottom: 15px;
      margin-top: 20px;
    }

    .color-box {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      margin-right: 10px;
    }

    .qunt-btn {
      width: 40px;
      height: 40px;
      font-size: 20px;
      font-family: "f1";
      border-radius: 100px;
      line-height: 4px;
      box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
        inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
        4px 5px 6px 1px rgba(149, 192, 234, 0.4);
      -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
        inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
        4px 5px 6px 1px rgba(149, 192, 234, 0.4);
      background: #ffffff;
    }
  }

  .modal-cls-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: "f1";
    border-radius: 100px;
    line-height: 4px;
    z-index: 111;
    box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
      inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
      4px 5px 6px 1px rgba(149, 192, 234, 0.4);
    -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
      inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
      4px 5px 6px 1px rgba(149, 192, 234, 0.4);
    background: #ffffff;

    img {
      width: 55%;
    }
  }
}

.cls-btn {
  right: 10px;
  top: 5px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-family: "f1";
  border-radius: 100px;
  line-height: 4px;
  z-index: 111;
  box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  background: #ffffff;

  img {
    width: 55%;
  }
}

.pop-modal-p {
  position: relative;

  p::after {
    content: "";
    left: -7px;
    top: 8px;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 10px;
    background: $primary1;
  }
}

.color-box1 {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  margin-right: 10px;
}

//product-popup-end
.bread-crumb {
  width: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  a,
  svg {
    font-family: "f4" !important;
    font-size: 16px;
    margin: 0 5px;
  }
}

.rit-pop1 .pack-box {
  border-radius: 7px;
}

.delivery_product {
  border-radius: 7px;
  height: 120px;

  .file-upload-input {
    width: 70%;
    height: 100%;
    cursor: pointer;

    img {
      width: 45px;
    }

    h6 {
      font-size: 17px;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
    }

    .file-input-overlay {
      position: absolute;
      left: 0;
      background: $lt-blue;
    }
  }
}

.tl-log-upload-box {
  label {
    height: 190px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input {
      opacity: 0 !important;
    }
  }
}

.log-upload-box {
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: inset -10px -9px 11px -4px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 6px -5px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -9px 11px -4px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 6px -5px rgba(149, 192, 234, 0.4);
  background: #f4f8ff;

  img {
    width: 70px;
  }

  p {
    font-family: "f2";
    font-size: 16px;
    margin-bottom: 0;
  }
}

.prev-img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.left-mock-req {
  overflow: scroll;
}

.shippment-succ {
  img {
    width: 100px;
    margin-bottom: 20px;
  }
}

.abt-hq {
  // p {
  //   text-align: justify;
  // }

  h4 {
    margin-top: -9%;
  }

  .box-mis-vis {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    box-shadow: inset -14px -8px 15px -3px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
    -webkit-box-shadow: inset -14px -8px 15px -3px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
    // background: #f4f8ff;

    img {
      width: 100px;
      margin-bottom: 12px;
    }
  }
}

.bg-team-border {
  background: #f2f7ff;
  padding: 15px;
  border-radius: 30px;
  margin: 0 15px;

  img {
    border-radius: 30px;
    margin-top: -3px;
  }

  .bg-team-border1 {
    padding: 10px;
  }
}

.join-abt {
  // background: url("../../img/our-team-back.jpg") no-repeat;
  // background-size: 100% 100%;
  background: #f2f7ff;

  .rit-img {
    width: 240px;

    img {
      width: 100%;
    }
  }
}

.blogg-img {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
  }
}

.blog-boxes {
  .blog-box {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 4px 3px 11px 5px rgba(157, 187, 216, 0.432);
    -webkit-box-shadow: 4px 3px 11px 5px rgba(157, 187, 216, 0.432);
    background: #fff;
    margin-bottom: 20px;

    .bloh-img {
      img {
        width: 100%;
      }
    }

    .title-blog-cat {
      border: 0;
      position: absolute;
      background: rgba(2, 69, 107, 0.8);
      padding: 5px 15px;
      top: 10px;
      left: 10px;
      border-radius: 5px;
    }
  }
}

.blog-enquiry {
  .rit-loop {
    .stay-loop {
      input {
        border: 0;
        // width: 100%;
        height: 55px;
        border-radius: 10px;
      }
    }
  }

  .blog-prod {
    width: 160px;
    margin-right: 20px;
  }
}

.cancel-btn {
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  background: #ffffff;
  // img{
  //   width: 10px;
  //   height: 10px;
  // }
}

.faq-banner {
  // height: 400px;
  background: url("../../img/FAQuser\ 2.png") no-repeat;
  background-size: 100%;
  width: 100%;
  height: 400px;
  // background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.tool-banner {
  // height: 500px;
  background: url("../../img/faq_back.png") no-repeat;
  // background-size: 100% 100%;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sus-banner {
  // height: 400px;
  background: url("../../img/sustainability.png") no-repeat;
  // background-size: 100% 100%;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.visit-help {
  width: 220px;
  border-radius: 10px;
  padding: 15px 5px;
  box-shadow: inset -10px -10px 10px -2px #d7e5f3, inset 5px 5px 12px 4px #fff;
  -webkit-box-shadow: inset -10px -10px 10px -2px #d7e5f3,
    inset 5px 5px 12px 4px #fff;
  background: #f1f8ff;

  img {
    width: 70px;
  }
}

.question-ans {
  margin-bottom: 50px;
  margin-top: 40px;
  padding: 0 30px;

  .faq-img {
    width: 250px;

    img {
      width: 100%;
    }
  }

  .left-quest {
    .search-faq {
      box-shadow: 4px 3px 8px 5px rgba(157, 187, 216, 0.432);
      -webkit-box-shadow: 4px 3px 8px 5px rgba(157, 187, 216, 0.432);
      background: #fff;
      margin-bottom: 20px;
      padding: 0px 20px;
      height: 70px;
      border-radius: 10px;
      width: 100%;

      input {
        border: 0;
        color: #000;
        width: 96%;
        height: 100%;
        background: transparent;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.meet-circle {
  z-index: 0;
  position: relative;

  .bg-team-border {
    h6 {
      height: 38px;
    }
  }
}

.plan-tl-box {
  .plan-box {
    border-radius: 15px;
    box-shadow: 0 0 10px -6px $primary1;
  }

  .list {
    img {
      width: 20px;
      height: 20px;
    }

    p {
      width: 100%;
      padding-left: 10px;
      margin-top: -2px;
    }
  }
}

.let-start-box {
  img {
    width: 100px;
  }
}

.how-work {
  img {
    width: 70px;
  }

  .number-btn {
    position: absolute;
    left: 20%;
    top: 10%;
  }

  h5 {
    width: 28px;
    height: 28px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: #fff;
    font-family: "f4";
  }
}

.trans-range {
  .pricing-pc-box {
    position: relative;
    z-index: 0;
    padding: 20px 30px;
    border-radius: 20px;
    box-shadow: inset -14px -8px 15px -3px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
    -webkit-box-shadow: inset -14px -8px 15px -3px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);

    .range-slider {
      background: $lt-blue1;
    }

    .range-slider__range {
      background-color: $primary !important;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      overflow: hidden;
    }

    .range-slider__thumb[data-lower] {
      width: 0;
    }

    #range-slider-gradient .range-slider__thumb[data-lower] {
      background: #0073e6;
    }

    .range-slider__thumb[data-upper] {
      background: #ffffff;
      box-shadow: 3px 3px 4px -2px #77a6c8;
    }

    #range-slider-gradient .range-slider__range[data-active],
    #range-slider-gradient
      .range-slider__thumb[data-active]
      ~ .range-slider__range {
      animation: move-bg 0.75s infinite linear;
    }

    .rit-pack-sample {
      width: 280px;
    }
  }

  .pric-per-pack {
    width: 100%;
    height: 2px;
    background: $lt-blue;
  }
}

.range-slider {
  .range-slider__thumb {
    width: 18px !important;
    height: 18px !important;
  }
}

.plan-tl-detail {
  .tit-box {
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: inset -14px -8px 15px -3px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
    -webkit-box-shadow: inset -14px -8px 15px -3px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
  }

  .plan-detail {
    width: 100%;
    margin-bottom: 20px;

    h6:first-child,
    p:first-child {
      width: 50%;
    }
  }
}

.let-start-box {
  box-shadow: inset 5px 7px 10px 5px #fff, inset 0px 3px 11px 7px #d8d8e7,
    5px 5px 10px 0px #eef1fd;
  -webkit-box-shadow: inset 5px 7px 10px 5px #fff,
    inset 0px 3px 11px 7px #d8d8e7, 5px 5px 10px 0px #eef1fd;
}

.team-circle {
  .img-box-met1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 40px;
    width: 160px;
    height: 160px;
    border-radius: 150px;
    box-shadow: inset 5px 7px 10px 5px #fff, inset 0px 0px 20px 15px #dfe4f3,
      5px 5px 10px 5px #e7ebf8;
    -webkit-box-shadow: inset 5px 7px 10px 5px #fff,
      inset 0px 0px 20px 15px #dfe4f3, 5px 5px 10px 5px #e7ebf8;
  }

  .img-box-met {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    border-radius: 150px;
    overflow: hidden;
    border: 8px solid #fff;
    margin: 10px 30px;
    transition: 0.2s all linear;

    img {
      width: 100%;
      height: 100%;
    }

    .over-lay {
      position: absolute;
      left: 0%;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      transition: 0.2s all linear;
      transform: scale(0, 0);
    }

    &:hover {
      .over-lay {
        left: 0%;
        transform: scale(1, 1);
        transition: 0.2s all linear;
      }
    }
  }
}

.sign-up-new {
  margin-top: 120px;
}

//dashboard-start
.dash-board-banner {
  width: calc(100% - 330px);

  .box {
    height: 100%;
    width: 96%;
    margin-left: 2%;
    overflow: hidden;
    border-radius: 30px;
    background: #dde5fc;

    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }

    .carousel-indicators [data-bs-target] {
      width: 27px;
      height: 5px;
      border-radius: 100px;
      background: $lt-primary;
      border: 0;
      opacity: 1;
      transition: 0.2s all linear;
    }

    .carousel-indicators .active {
      width: 31px;
      height: 5px;
      border-radius: 100px;
      background: $primary;
      border: 0;
      opacity: 1;
      transition: 0.2s all linear;
    }

    .carousel-inner {
      width: 100%;
      height: 100%;
      display: flex;
      background: #dde5fc;

      // align-items: center;
      // justify-content: center;
      .caption-banner {
        width: 100%;
        height: 100%;

        .left-banner {
          width: 60%;

          p {
            width: 110px;
            text-align: center;
          }

          h3 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }

          button {
            z-index: 11;
            position: absolute;
            bottom: 30px;
            left: 30px;
          }

          .btn-back {
            width: 260px;
            height: 260px;
            border-radius: 100%;
            background: $primary1;
            position: absolute;
            bottom: -50px;
            left: -30px;
            opacity: 0.1;
            z-index: 0;
          }
        }

        .rit-banner {
          width: 40%;
          height: 100%;
          position: relative;
          margin: auto;
          display: flex;
          align-items: center;
          z-index: 0;

          .over-lay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0);
            z-index: 111;
          }

          img {
            width: 65%;
            // height: 100%;
          }
        }
      }
    }
  }
}

.whats-new {
  .box {
    width: 100%;
    margin-left: 5%;
    overflow: hidden;
    border-radius: 30px;

    img {
      width: 70px;
      margin-bottom: 13px;
    }
  }
}

.order-box {
  .box {
    border: 2px solid $lt-blue1;
    border-radius: 30px;
  }
}

//dashboard-end
//
//
//
//my-product-start
.my-product {
  .top-product {
    .active {
      width: 60%;
      height: 2px;
      border-radius: 10px;
      background: $primary1;
      left: 20%;
      bottom: 0;
    }
  }
}

.filter-product {
  input::placeholder {
    color: $primary1;
    font-size: 15px;
    font-family: "f2";
  }

  .search-icon {
    img {
      width: 20px;
    }
  }
}

.login-product {
  .logist {
    width: 20px;
    height: 20px;
  }

  // .redeemlist {
  // }
}

.show-btn-cart {
  bottom: 30px;
  transition: 0.2s all ease-in-out;
}

.hid-btn-cart {
  bottom: 130%;
  transition: 0.2s all linear;
}

.img-box-tee {
  width: 70px;
}

.img-box-tee2 {
  height: 46px;
  width: 46px;
}

.add-cart {
  position: fixed;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  transition: 0.2s all linear;

  .inner-box {
    border-radius: 15px;
    background: #fff;
    box-shadow: inset 1px 7px 8px 2px #fff, inset -5px 0px 26px 7px #d8eaf7,
      0 0px 2px -1px #7a8796;
    -webkit-box-shadow: inset 1px 7px 8px 2px #fff,
      inset -5px 0px 26px 7px #d8eaf7, 0 0px 2px -1px #7a8796;
  }

  .close-btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $lt-blue1;
    border-radius: 100%;
    box-shadow: inset 0px 0px 9px 1px #3a52a0, inset -5px 0px 26px 7px #d8eaf7,
      3px 8px 6px 0px #7d9bbe;
    -webkit-box-shadow: inset 0px 0px 9px 1px #3a52a0,
      inset -5px 0px 26px 7px #d8eaf7, 3px 8px 6px 0px #7d9bbe;
  }

  .btn-top {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.close-btn11 {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $lt-blue1;
  border-radius: 100%;
  box-shadow: inset 0px 0px 9px 1px #3a52a0, inset -5px 0px 26px 7px #d8eaf7,
    3px 8px 6px 0px #7d9bbe;
  -webkit-box-shadow: inset 0px 0px 9px 1px #3a52a0,
    inset -5px 0px 26px 7px #d8eaf7, 3px 8px 6px 0px #7d9bbe;
}

.img-wrapper {
  overflow: hidden;
}

img.hover-zoom:hover {
  transform: scale(25);
}

.product-list-detail {
  .box-shadow-product {
    padding: 10px 30px 20px;
    width: 340px;
    border-radius: 20px;
    box-shadow: inset -9px -9px 8px 0px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
  }

  .box-shadow-product_new {
    padding: 20px 20px 20px;
    width: 460px;
    border-radius: 20px;
    box-shadow: inset -9px -9px 8px 0px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
  }

  .box-shadow-product2 {
    // padding: 10px 30px 20px;
    width: 670px;
    border-radius: 20px;
    box-shadow: inset -9px -9px 8px 0px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
  }

  .box-shadow-product_view {
    padding: 10px 30px 20px;
    width: 340px;
    border-radius: 20px;
    box-shadow: inset -9px -9px 8px 0px rgba(159, 200, 233, 0.31),
      inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45), 3px 1px 11px 1px #2d98c7;
  }

  h3 {
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 14px;
  }
}

.text-area-product {
  width: 100%;
  border: 2px solid $lt-blue1;
  background: transparent;
  border-radius: 15px;
}

.show-img {
  width: 50% !important;
}

.jgzVON {
  border-radius: 30px !important;
  box-shadow: 11px 11px 17px -8px #b3c6d8;
  margin-bottom: 30px;
}

.line1 {
  width: 100%;
  height: 1px;
  background: $lt-blue1;
}

.pop-input input {
  border: 2px solid $lt-blue1;
  border-radius: 18px;
  margin-top: 10px;
  padding: 8px 13px;

  &::placeholder {
    font-size: 15px;
    font-family: "f1";
    color: $lt-blue1;
  }
}

//my-product-end
//
//
//

//my-order-start
.order-screen .active {
  width: 65px;
  height: 3px;
  background: $primary1;
}

.qunt-btn1 {
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-family: "f1";
  border-radius: 100px;
  line-height: 4px;
  box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  background: #ffffff;
}

.prod-list-box-tl1 {
  padding-bottom: 35px;
  width: 170px;
  height: 200px;

  .prod-list-boxss {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    padding: 10px;
    border-radius: 25px;
    box-shadow: 11px 11px 20px -7px #bacff0;
    -webkit-box-shadow: 11px 11px 20px -7px #bacff0;

    img {
      width: 100%;
      height: 100%;
      margin: auto;
      display: block;
    }
  }
}

.inner-ship-stor {
  box-shadow: inset -14px -8px 15px -3px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 11px 5px rgba(179, 209, 240, 0.2);
  -webkit-box-shadow: inset -14px -8px 15px -3px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 11px 5px rgba(179, 209, 240, 0.2);
  background: #f4f8ff;
  border-radius: 20px;
  margin-top: 20px;
  width: 450px;
  overflow: hidden;
}

//my-order-end

//my-shipment-start
.ship-img {
  position: absolute;
  bottom: 0px;
  right: 0px;

  img {
    width: 100px;
  }
}

.tl-shipment-screen {
  width: 100%;

  .req-summary {
    position: fixed;
    right: 0;
    border: 2px solid $lt-blue1;
    overflow-y: scroll;
    background: #fff;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 20px;
  }
}

.memb-dics {
  width: 100%;
  border-radius: 7px;
  padding: 7px 6px;
  background: $lt-blue1;
}

.tl-update-qty {
  z-index: 1;
  transition: 0.2s all linear;

  .update-qty {
    position: fixed;
    overflow: scroll !important;
    z-index: 1;
    top: 0;
    width: 70%;
    background: $white;
    padding: 10px 20px;
    height: 100vh;
    transition: 0.2s all linear;
  }

  .back-update-qty {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    transition: 0.2s all linear;
    right: 0;
  }

  .prod-pack {
    .commentIcon {
      width: 17px;
      height: 17px;
      margin-top: 5px;
      margin-left: 10px;
    }
  }
}

//Kaviya-shipment-screen
.naviPtag {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splitView {
  height: 17px;
  width: 3px;
  margin: 0px 10px 16px 0px;
}

.parag2 {
  font-family: "f2";
  color: $primary1;
  font-size: 13px;
}

.checkBoxFont {
  font-family: "f2";
  font-size: 13px;
  margin: 3px 0px 0px 8px;
}

.productImg {
  height: 40px;
  width: 40px;
}

.productImg1 {
  height: 48px;
  width: 48px;
}

.product-list-detail1 {
  display: flex;
  width: 100%;

  .box-shadow-product1 {
    height: 42px;
    padding: 3px 3px 3px 3px;
    width: 42px;
    border-radius: 10px;
    background-color: white;
    box-shadow: inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
      4px 3px 11px 5px rgba(179, 209, 240, 0.2);
  }

  .tickBox {
    height: 25px;
    width: 25px;
    padding: 0px 0px 0px 0px;
    align-items: center;
  }

  .tickBoxDesign {
    height: 20px;
    width: 20px;
    padding: 3px;
  }
}

.tickBoxDesign1 {
  height: 20px !important;
  width: 20px !important;
  padding: 1px;
  color: white;
  background-color: $primary;
}

.tickBoxDesign2 {
  height: 20px !important;
  width: 20px !important;
  padding: 1px;
  color: $primary1;
  background-color: transparent;
  border-color: $primary1 !important;
  border: $primary1 2px solid !important;
}

.selectCheckBox {
  align-items: center;
}

.tickBoxDesign4 {
  height: 20px !important;
  width: 20px !important;
  background-color: transparent !important;
  border: black 2px solid !important;
  color: white;
}

.navView {
  display: flex;
}

.blog-Text {
  margin-top: 60px;
}

.bannerImge {
  height: 170px;
  width: 170px;
}

.addrecipient {
  justify-content: center;
  align-items: center;
  display: grid;
  margin-top: 20px;
}

.addrecipient1 {
  justify-content: center;
  align-items: center;
  display: grid;
  color: $primary1 !important;
  margin-top: 20px;
}

//my-shipment-end

//  Numbers Btn Start
// .num-cls-btn-cont {
//   // background: pink;
//   // width: 20px;
// }

.num-cls-btn {
  width: 36px;
  height: 36px;
  border-radius: 7px;
  box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  background: #ffffff;
}

.right-box-cont {
  width: 350px;
}

// Number Box end

// Membership Discount start
.dis-icon {
  height: 20px;
  width: 20px;
}

.Under-Line {
  height: 2px;
}

// Membership Discount end
// profile-start
.profile-screen {
  width: 100%;
  padding: 20px 30px;

  .profile-input {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .box {
    width: 48%;
    margin-top: 10px;
    padding: 10px;

    .p_box {
      font-size: 19px;
      color: #839dd1;
      font-family: "f2";
      margin-bottom: 5px;
    }

    select {
      font-size: 18px !important;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;

      &::placeholder {
        color: #d1def9;
      }
    }
  }

  .profile-action {
    align-items: center;
    justify-content: space-between;
    width: 33%;
    padding: 10px;

    button {
      width: 46%;
      height: 47px;
      margin-top: 0px;
      font-size: 16px;
      font-family: "f3";
    }
  }
}

// profile-screen end

// shipping screen
.shipping-input {
  font-size: 18px;
  font-family: f2;
  width: 100%;
  height: 48px;
  background: #f3f7ff;
  border-radius: 10px;
  border: 0;
  box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
  -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
    inset -5px -5px 9px 1px #fff;
  padding: 0 20px;

  &::placeholder {
    color: #d1def9;
  }
}

// company screen start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow: -10px -10px 30px 0px #f4f8ff,
      10px 10px 30px 0px rgba(199, 213, 239, 0.2),
      -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
      10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }
    }
  }

  .upload-btn {
    margin-left: 30px;
    padding: 10px 35px;
    border-radius: 10px;
    margin-top: 0px;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    border: 3px solid $primary;
    text-align: center;
    align-self: center;
  }
}

// company screen End

// Account Membership start
.membership {
  margin-top: 40px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .plan {
    display: flex;
    align-items: center;
    width: 35%;
    padding: 25px 15px;
    border-radius: 30px;
    box-shadow: -10px -10px 30px 0px #f4f8ff,
      10px 10px 30px 0px rgba(169, 186, 216, 0.4),
      -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
      10px 10px 10px 0px #fff inset;

    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
    }

    div {
      margin-left: 10px;

      p:first-of-type {
        color: #14213d;
        font-family: "f4";
        font-size: 18px;
        margin-bottom: 8px;
      }

      p:last-of-type {
        font-family: "f3";
        font-size: 12px;
        color: #fff;
        padding: 5px 14px;
        background-color: #0e5af2;
        border-radius: 5px;
        text-align: center;
      }
    }
  }

  .upgrade {
    border-radius: 10px;
    background: #fca311;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 19px;

    p {
      margin: 0;
      font-family: "f3";
      font-size: 13px;
      color: white;
    }
  }
}

.benefits {
  width: 40%;
  margin-top: 50px;

  p {
    margin: 0;
    font-family: "f4";
    font-size: 16px;
  }

  .points-wrap {
    margin-top: 25px;

    .single-point {
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        p {
          margin-left: 10px;
          color: #839dd1;
          font-size: 14px;
          font-family: "f1";
        }
      }

      p {
        font-size: 14px;
        font-family: "f2";
      }
    }
  }
}

// Account Membership End

// Transaction start
.no-orders {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 70vh;

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  p {
    color: #839dd1;
    font-family: "f2";
    font-size: 16px;
  }
}

// your Storage Start
.right-said-box {
  width: 670px !important;
  justify-content: space-between;
}

.file-upload-input1 {
  height: 150px;
  box-shadow: inset -10px -9px 11px -4px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 6px -5px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -9px 11px -4px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 6px -5px rgba(149, 192, 234, 0.4);
  background: #f4f8ff;

  .upload1 {
    width: 45px;
    height: 45px;
  }
}

// Redeem Style

//Kavi Design
.continueBtn {
  bottom: 70px;
  right: 70px;
}

.continueBtn1 {
  bottom: 70px;
  right: 630px;
  z-index: 1;
}

.continueBtn2 {
  bottom: 70px;
  right: 630px;
}

.parag4 {
  font-family: "f2";
  color: $primary1;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}

.arrowImg1 {
  margin-bottom: 16px;
}

.alignQuantity {
  margin-top: 360px;
}

.minText {
  color: $primary1;
}

.custPopup {
  position: fixed;
  bottom: 110px;
  left: 480px;
  display: grid;
  border: $lt-blue 3px solid;
  background-color: white;
  box-shadow: inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    6px 6px 11px 7px rgba(179, 209, 240, 0.2);
}

.addReciView {
  width: 100%;
}

.tickBoxDesign6 {
  height: 16px !important;
  width: 16px !important;
  padding: 1px;
  color: white;
  background-color: $primary;
}

.searchImgIcon {
  height: 20px;
  width: 20px;
  object-fit: cover;
}

.filterView {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 100px;
}

.filterView1 {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 100px;
  margin-right: 100px;
}

.filterDesign {
  color: $primary1 !important;
  margin-bottom: 0;
}

.seletDesign {
  color: $primary1;
}

.tickBoxDesign11 {
  height: 16px !important;
  width: 16px !important;
  padding: 1px;
  color: white;
  background-color: $primary;
}

.tickBoxDesign41 {
  height: 16px !important;
  width: 16px !important;
  background-color: transparent !important;
  border: gray 2px solid !important;
  color: white;
}

.filterDesign1 {
  color: $primary1 !important;
  margin-bottom: 0;
  width: 20%;
  margin-left: 30px;
}

.filterDesign2 {
  color: $primary1 !important;
  margin-bottom: 0;
  width: 35%;
}

.filterDesign3 {
  color: $primary1 !important;
  margin-bottom: 0;
  width: 20%;
}

.filterDesign11 {
  margin-bottom: 0;
  width: 20%;
  margin-left: 30px;
}

.filterDesign21 {
  margin-bottom: 0;
  width: 35%;
}

.filterDesign31 {
  margin-bottom: 0;
  width: 20%;
}

.profile-screen1 {
  width: 100%;
  padding: 20px 30px;

  .profile-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .box4 {
      width: 48%;
      margin-top: 10px;
      padding: 10px;

      select {
        font-size: 18px !important;
        font-family: f2;
        width: 100%;
        color: $primary1;
        height: 48px;
        background: #f3f7ff;
        border-radius: 10px;
        border: 0;
        box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
        -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
          inset -5px -5px 9px 1px #fff;
        padding: 0 20px;
      }

      input {
        font-size: 18px;
        font-family: f2;
        width: 100%;
        height: 48px;
        background: #f3f7ff;
        border-radius: 10px;
        border: 0;
        box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
        -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
          inset -5px -5px 9px 1px #fff;
        padding: 0 20px;

        &::placeholder {
          color: #d1def9;
        }
      }
    }
  }

  .box {
    width: 48%;
    margin-top: 10px;
    padding: 10px;

    select {
      font-size: 18px !important;
      font-family: f2;
      width: 100%;
      color: $primary1;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;

      &::placeholder {
        // color: #d1def9;
        color: #07679c;
      }
    }
  }

  .box2 {
    width: 48%;
    margin-top: 10px;
    padding: 10px;

    select {
      font-size: 18px !important;
      font-family: f2;
      color: $primary1;
      border-radius: 10px;

      height: 48px;
      background: #f3f7ff;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 90%;
      height: 48px;
      margin-left: 40px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;

      &::placeholder {
        color: #d1def9;
      }
    }
  }

  .boxShirt {
    width: 100%;
    margin-top: 10px;
    padding: 10px;

    select {
      font-size: 18px !important;
      font-family: f2;
      width: 47%;
      // color: $primary1;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;

      &::placeholder {
        color: #d1def9;
      }
    }
  }

  .box1 {
    width: 100%;
    margin-top: 10px;
    padding: 10px;

    select {
      font-size: 18px !important;
      font-family: f2;
      width: 100%;
      // color: $primary1;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;

      &::placeholder {
        color: #d1def9;
      }
    }
  }

  .profile-action {
    align-items: center;
    justify-content: space-between;
    width: 33%;
    padding: 10px;

    button {
      width: 46%;
      height: 47px;
      margin-top: 0px;
      font-size: 16px;
      font-family: "f3";
    }
  }
}

.filterDesignText {
  color: $primary1 !important;
  margin-bottom: 0;
  margin-left: 30px;
}

// Redeem Slide Image Start
.slide-box-img {
  height: 130px !important;
  width: 130px !important;
  border-radius: 14px;
}

// Redeem Slide Image End

// Select List start
// .select-list {
//   // line-height: 50px;
// }

.select-list:hover {
  background-color: $gray3;
  align-items: center;
}

.select_input {
  font-size: 18px;
  font-family: f2;
  width: 100%;
  height: 50px !important;
  background: #f3f7ff;
  border-radius: 20px;
  border: 0;
  box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
  -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
    inset -5px -5px 9px 1px #fff;
}

.icon-pop2 {
  height: 38px;
  width: 200px;
  top: 20px;
  left: 14px;
  // visibility: hidden;
  opacity: 0;
  // bottom: 0;
  background-color: #fff;
  box-shadow: 7px 7px 6px 0px #969697, -5px -5px 9px 1px #969697;
  -webkit-box-shadow: 7px 7px 6px 0px #969697, -5px -5px 9px 1px #fff;
  border-radius: 10px;
  transition: 0.5s;

  p {
    font-size: 9px !important;
  }
}

// .icon-pop1:hover div {
// visibility: visible;
// top: 20px;
// opacity: 1;
// }/

.icon-pop1:hover .icon-pop2 {
  opacity: 1;
}

.select_box_slide {
  // padding: 10px 30px 20px;
  width: 670px;
}

.select_box_slide:hover {
  border-radius: 20px;
  box-shadow: inset -9px -9px 8px 0px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 11px 5px rgba(179, 209, 240, 0.2);
  cursor: pointer;
}

.slide_cont {
  height: 100px;
  width: 200px;
  // background: pink !important;
}

// Select List end

.date-align {
  right: 27px;
  bottom: 27px;
}

//Kavi Design
//Integrations
.timeCont {
  align-items: center;
}

.Integration-screen {
  width: 100%;
  padding: 20px 30px;

  .profile-input {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .box {
    width: 48%;
    margin-top: 10px;
    padding: 10px;

    select {
      font-size: 18px !important;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;

      &::placeholder {
        color: #d1def9;
      }
    }
  }

  .profile-action {
    align-items: center;
    justify-content: space-between;
    width: 33%;
    padding: 10px;

    button {
      width: 46%;
      height: 47px;
      margin-top: 0px;
      font-size: 16px;
      font-family: "f3";
    }
  }
}

.consultBanner {
  // height: 510px;
  background: url("../../img/faq_back.png") no-repeat;
  background-size: 100% 100%;
  // border-radius: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
}

// filter icon
.filter-options {
  z-index: 1111;
  top: 40px;
  width: 130px;
  padding: 10px;
  right: 0;
  border-radius: 10px;
  border: 2px solid rgba(199, 213, 239, 0.5);
  background: #f4f8ff;
  box-shadow: 10px 10px 20px 0px rgba(199, 213, 239, 0.4);

  p {
    color: #14213d;
    font-family: "f1";
    font-size: 12px;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      color: #839dd1;
      font-family: "f1";
      font-size: 14px;

      &.active {
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }
  }
}

// Remove select arrow
.rm-select-arrow {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.dropdownbox {
  position: relative;
  display: flex;
  align-items: center;

  .arrow-icon {
    position: absolute;
    left: 23px;
    width: 12px;
    height: 12px;
    object-fit: contain;
    pointer-events: none;
  }
}

// Redeem Form
.form_cont {
  background: #fff;
  height: 235px;
  width: 486px;
  border-radius: 25px;
}

.form-box-shadow {
  box-shadow: inset -9px -9px 8px 0px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 11px 5px rgba(179, 209, 240, 0.2);
}

// Theem Select Screen start
.theem_select {
  height: 66px;
  width: 66px;
}

.font-align {
  left: 514px;
  bottom: -37px;
}

// Theem Select Screen end

.deselectView {
  width: 100%;
  height: 11%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.doneIconDesign {
  display: flex;
  justify-content: flex-end;
}

.sideDetails {
  height: 65%;
  width: 30%;
  position: fixed;
  right: 0;
  bottom: 90px;
}

.itemImgDetails {
  height: 50px;
  width: 55px;
}

.reorder-qty {
  .update-qty {
    overflow: scroll !important;
    top: 0;
    width: 100%;
    background: $white;
    padding: 10px 20px;
    transition: 0.2s all linear;
  }

  .back-update-qty {
    width: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    transition: 0.2s all linear;
    right: 0;
  }

  .prod-pack {
    .commentIcon {
      width: 17px;
      height: 17px;
      margin-top: 5px;
      margin-left: 10px;
    }
  }
}

.productImgReOrer {
  height: 500px;
  width: 500px;
  margin-top: 40px;
}

.color_choos {
  box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff !important;
  -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
    inset -5px -5px 9px 1px #fff !important;
}

.color-pic {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 29px;
  height: 29px;
  cursor: pointer;
  border-radius: 10px;
  border: #878787 sol;
  border: 0;
}

.color-pic::-webkit-color-swatch {
  border-radius: 5px;
}

.tl-shipment-qty {
  z-index: 1;
  transition: 0.2s all linear;

  .update-qty {
    position: fixed;
    overflow: scroll !important;
    z-index: 1;
    top: 0;
    width: 60%;
    background: $white;
    padding: 20px 40px 20px;
    height: 100vh;
    transition: 0.2s all linear;
  }

  .back-update-qty {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    transition: 0.2s all linear;
    right: 0;
  }

  .prod-pack {
    .commentIcon {
      width: 17px;
      height: 17px;
      margin-top: 5px;
      margin-left: 10px;
    }
  }
}

textarea {
  font-size: 18px;
  font-family: f2;
  width: 100%;
  height: 100px;
  background: #f3f7ff;
  border-radius: 10px;
  padding: 8px 14px !important;
  border: 0;
  box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
  -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
    inset -5px -5px 9px 1px #fff;
  padding: 0 20px;
  resize: none !important;
}

// Image select start
label.label input[type="file"] {
  position: absolute;
  top: -1000px;
}

.label {
  cursor: pointer;

  display: inline-block;
}

// .label:hover {
//   // color: #180bff;
// }

.label:active {
  color: #383078;
}

.label:invalid + span {
  color: #000000;
}

// .label:valid + span {
// }

// Image select end

// Said image start
.custom-img-form {
  height: 200px;
  width: 200px;
}

.file-up-img {
  height: 86px;
  width: 86px;
}

.h-custom1 {
  margin-top: 14rem;
}

.h-custom2 {
  margin-top: 11rem;
}

.h-custom3 {
  margin-top: 13rem;
}

.h-custom4 {
  margin-top: 10rem;
}

.h-custom {
  margin-top: 13rem;
  align-items: end;
  height: 20vh;
  justify-content: end;
}

.form_cont2 {
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.custom-img-form2 {
  height: 100px !important;
  width: 100px !important;
  object-fit: contain;
  padding: 10px;
}

// Said image end

.custom-box {
  width: 100%;
  // margin-top: 10px;
  padding: 10px;

  select {
    font-size: 18px !important;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;

    &::placeholder {
      color: #d1def9;
    }
  }
}

.Integration-screen3 {
  width: 100%;
  padding: 20px 30px;

  .profile-input {
    width: 1000%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .box {
    width: 100%;
    // margin-top: 10px;
    padding: 10px;

    select {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;

      &::placeholder {
        color: #d1def9;
      }
    }
  }

  .profile-action {
    align-items: center;
    justify-content: space-between;
    width: 33%;
    padding: 10px;

    button {
      width: 46%;
      height: 47px;
      margin-top: 0px;
      font-size: 16px;
      font-family: "f3";
    }
  }
}

// Form Left start
.left-form {
  height: calc(100vh - 80px) !important;
}

.custom-w-h {
  height: 180px !important;
  width: 30px I !important;
  object-fit: contain !important;
  align-self: center !important;
  border-radius: 15px !important;
}

// Form Left end

.box1 {
  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
  }

  ::placeholder {
    color: #839dd1;
    opacity: 0.5;
    font-family: "f2";
    font-size: 16px;
  }
}

.box3 {
  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    // background: #f3f7ff;
    border-radius: 10px;
    border-style: solid !important;
    border-color: #e2ecff !important;

    // box-shadow:
    //   inset 7px 7px 6px 0px #d6e0f5,
    //   inset -5px -5px 9px 1px #fff;
    // -webkit-box-shadow:
    //   inset 7px 7px 6px 0px #d6e0f5,
    //   inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
  }

  ::placeholder {
    color: #839dd1;
    opacity: 0.5;
    font-family: "f2";
    // font-size: 16px;
  }
}

.margin-custom {
  margin-left: 14%;
}

.box-none {
  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    float: left !important;
    background: transparent;
    border-radius: 10px;
    border: 0;
    text-align: center;
    width: 84px;
    // box-shadow:
    //   inset 7px 7px 6px 0px #d6e0f5,
    //   inset -5px -5px 9px 1px #fff;
    // -webkit-box-shadow:
    //   inset 7px 7px 6px 0px #d6e0f5,
    //   inset -5px -5px 9px 1px #fff;
    // padding: 0 20px;
  }

  ::placeholder {
    color: #839dd1;
    opacity: 0.5;
    font-family: "f2";
    font-size: 16px;
  }
}

.form_cont3 {
  background: #fff;
  height: 327px !important;
  width: 486px;
  border-radius: 25px;
}

.center-content3 {
  background: #f4f8ff;
  border-radius: 30px;
  position: fixed;
  // top: 5vh;
  width: 50%;
  height: 45vh;
  overflow: scroll;
}

.borer-r {
  border: 2px solid #e2ecff !important;
  // border-bottom-left-radius: 25px !important;
}

.down_imgs {
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 3px 5px 28px 8px #d5e5fb;
  margin-left: 10px;
  cursor: pointer;
}

.image-gallery-right-nav .image-gallery-svg {
  height: 25px !important;
  width: 10px !important;
}

.image-gallery-left-nav .image-gallery-svg {
  height: 25px !important;
  width: 10px !important;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 5px !important;
}

.image-gallery-index {
  display: none !important;
}

.product-slide {
  position: relative;
  padding-right: 20px;
}

.rightArrow {
  position: absolute;
  top: 40px;
  right: -3px;
  color: #07679c;
}

// Popup Style Start
.center-content2 {
  background: #f4f8ff;
  border-radius: 30px;
  position: fixed;
  top: 5vh;
  width: 86%;
  height: 87vh;
  overflow: scroll;
  // position: relative;
}

.select-custom-boxes {
  border-style: solid !important;
  border-color: #e2ecff !important;
}

.color-custom-ch {
  color: red !important;
}

// Popup Style End
.form-check-input {
  width: 40px !important;
  height: 20px !important;
}

.form-check-input:checked {
  background-color: #30be17 !important;
  border-color: #fff !important;
}

// Form Input Box start
.box1 {
  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
  }

  ::placeholder {
    color: #839dd1;
    opacity: 0.5;
    font-family: "f2";
    font-size: 16px;
  }
}

.box3 {
  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    // background: #f3f7ff;
    border-radius: 10px;
    border-style: solid !important;
    border-color: #e2ecff !important;
    // box-shadow:
    //   inset 7px 7px 6px 0px #d6e0f5,
    //   inset -5px -5px 9px 1px #fff;
    // -webkit-box-shadow:
    //   inset 7px 7px 6px 0px #d6e0f5,
    //   inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
  }

  ::placeholder {
    color: #839dd1;
    opacity: 0.5;
    font-family: "f2";
    // font-size: 16px;
  }
}

.margin-custom {
  margin-left: 14%;
}

.Integration-screen2 {
  width: 100%;

  // padding: 20px 30px;
  .profile-input {
    width: 1000%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .box {
    width: 100%;
    margin-top: 10px;
    padding: 10px;

    select {
      font-size: 18px !important;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }

    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow: inset 7px 7px 6px 0px #d6e0f5, inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow: inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;

      &::placeholder {
        color: #d1def9;
      }
    }
  }

  .profile-action {
    align-items: center;
    justify-content: space-between;
    width: 33%;
    padding: 10px;

    button {
      width: 46%;
      height: 47px;
      margin-top: 0px;
      font-size: 16px;
      font-family: "f3";
    }
  }
}

.borer-r {
  border: 2px solid #e2ecff !important;
  // border-bottom-left-radius: 25px !important;
}

.borer-r1 {
  border: 3px solid #e2ecff !important;
  // border-bottom-left-radius: 25px !important;
}

// Form Input Box end
// icon Alignment start
.icon1 {
  position: absolute;
  height: 81px !important;
  width: 24px !important;
  margin-left: 11px;
}

.icon2 {
  position: absolute;
  height: 80px !important;
  width: 28px !important;
  margin-left: -42px;
}

// icon Alignment end
// box shadow
.shadow-box {
  background-color: #07679c;
  box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  -webkit-box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  background: #f4f8ff;
}

.shadow-box1 {
  box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  -webkit-box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  background: #f4f8ff;
}

.shadow-box2 {
  box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  -webkit-box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  background: #f4f8ff;
}

.add_img {
  width: 70px;
  height: 70px;
  background-color: $lt-blue1;
  border-radius: 10px;
  cursor: pointer;
}

.slide_image {
  // width: 500px;
  width: 200px;
  height: 200px;
  // background-color: red;
  border-radius: 20px;
  border-color: $primary;
  border-style: solid;
  border-width: 2px;
}

.filter-menu-build {
  width: 178px;
  position: relative;
}

.popup {
  position: absolute;
  height: 102px;
  overflow: scroll;
  width: 205px;
  background-color: whitesmoke;
  margin-top: 10px;
  padding: 10px 15px 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  z-index: 111;
  // &::-webkit-scrollbar{
  //   width: 4px;
  // }
}

.rangepopup {
  height: 100px;
  width: 190px;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  z-index: 111;
}

.colors {
  width: 25px;
  height: 25px;
  background-color: palevioletred;
  border-radius: 6px;
  margin-top: 10px;
}

.colors1 {
  width: 25px;
  height: 25px;
  background-color: #878787;
  border-radius: 6px;
  margin-top: 10px;
}

.colors2 {
  width: 25px;
  height: 25px;
  background-color: darkblue;
  border-radius: 6px;
  margin-top: 10px;
}

.colors3 {
  width: 25px;
  height: 25px;
  background-color: #0073e6;
  border-radius: 6px;
  margin-top: 10px;
}

.colors5 {
  width: 25px;
  height: 25px;
  background-color: #0073e6;
  border-radius: 6px;
  margin-top: 10px;
}

.colors4 {
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 6px;
  margin-top: 10px;
}

.img {
  margin: 2px;
  width: 25px;
  height: 25px;
  background-color: #0073e6;
  border-radius: 6px;
  margin-bottom: 4px;
}

.imgs {
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: 9px;
  margin-left: 10px;
  gap: 5px;
}

.shad {
  box-shadow: -1.45px -1.45px 5.82px 0px #b2c3e133;

  box-shadow: 3.64px 3.64px 3.64px 0px #b2c3e196;

  box-shadow: -7.27px -7.27px 7.27px 0px #064f77cc inset;

  box-shadow: 7.27px 7.27px 5.82px 0px #228ac3cc inset;
}

.inputcontainer {
  padding-left: 35px;
  border-radius: 20px;
  width: 300px;
  height: 39px;
  box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  -webkit-box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  background: #f4f8ff;
  background-color: white;
}

::-webkit-input-placeholder {
  color: "#BCBCBC";
}

.filters {
  font-size: 18;
  font-weight: 600;
}

.filtertxt {
  font-size: 18;
  font-weight: 600;
  color: gray;
}

.messageIcon {
  width: 20px;
  height: 20px;
}

.messagePopup {
  margin-top: 10px;
  height: 250px;
  width: 350px;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: inset 1px 7px 8px 2px #fff,
    inset -5px 0px 26px 7px #d8eaf7, 0 0px 2px -1px #dce3eb;
  border-radius: 10px;
}

.messagelogo {
  width: 80px;
}

.icons {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.show {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: whitesmoke;
}

.headertitle {
  font-size: 18px;
  font-weight: 600;
}

.headertitle1 {
  font-size: 18px;
  font-weight: 400;
}

.messager {
  width: 20px;
  height: 20px;
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.topvalue {
  top: -280px;
}

.btn-cust {
  background: linear-gradient(#0692df, #02456b 90%);
}

.iconbg {
  background: transparent;
  border: 0;
  background: #f1f7fc;
  box-shadow: inset 5px 7px 10px 5px #fff, inset 0px 3px 11px 7px #d8d8e7,
    5px 5px 10px 0px #eef1fd;
  -webkit-box-shadow: inset 5px 7px 10px 5px #fff,
    inset 0px 3px 11px 7px #d8d8e7, 5px 5px 10px 0px #eef1fd;
  border-radius: 15px;
  padding: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 70px;
}

.owl-next,
.owl-prev {
  background: transparent;
  border: 0;
  background: #f1f7fc;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  // -webkit-box-shadow: inset 5px 7px 10px 5px #fff,
  //   inset 0px 3px 11px 7px #d8d8e7, 5px 5px 10px 0px #eef1fd;
  border-radius: 50px;
  padding: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.owl-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.pac-box3 {
  // width: 33%;
  margin-top: 10px;
  padding: 0 3%;

  .pac-boxs3 {
    border-radius: 30px;
    background: #fff;
    box-shadow: inset -2px 2px 4px 3px #fff,
      10px 10px 13px -5px rgba(161, 183, 219, 0.44);
    -webkit-box-shadow: inset -2px 2px 4px 3px #fff,
      10px 10px 13px -5px rgba(161, 183, 219, 0.44);
    margin-bottom: 20px;
    padding: 30px 10px;
    width: 250px;

    .top-img {
      width: 70%;
      margin: auto;

      .img1 {
        width: 100%;
      }
    }

    .logist {
      width: 20px;
      height: 20px;
    }

    h5 {
      font-size: 20px;
      margin-top: 10px;
      color: #000;
      font-family: "f4";
    }
  }
}

.preset-kit-box {
  margin-bottom: 20px;
  padding: 30px 10px;
  width: 250px;
  background: #fff;
  border-radius: 30px;
  box-shadow: inset -2px 2px 4px 3px #fff, 10px 10px 13px -5px #a1b7db70;
  -webkit-box-shadow: inset -2px 2px 4px 3px #fff, 10px 10px 13px -5px #a1b7db70;
}

// Sankari

.OwlCarousel_cust {
  width: 100% !important;

  .owl-stage {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .slisd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .owl-item {
    width: 260px !important;
  }

  .owl-stage {
    width: 100% !important;
    gap: 1rem;
  }
}

.inputcontainer2 {
  border-radius: 20px;
  box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  -webkit-box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  background: #f4f8ff;
  background-color: white;
  width: 60%;

  .imgs2 {
    width: 20px;
    height: 20px;
    gap: 5px;
  }
}

.btncontadd {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
}

.botton_btn {
  position: fixed;
  bottom: 10px;
  right: 30px;
}

.listcont {
  border-radius: 10px;
  box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  -webkit-box-shadow: -5px 5px 5px -2px #d1e0f8, inset -5px 5px 4px -3px #dbe5f8;
  background: #f4f8ff;
  background-color: white;

  .closebtns {
    // height: 20px;
    // width: 20px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    // -webkit-box-shadow: 5px 5px 5px -1px #abc6f1;
    // background: linear-gradient(#0692df, #02456b 90%);
  }
}

.listbtn {
  width: 50%;
}

// Font Res
// fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2

// ERROR
.err-input {
  position: absolute;
  // right: 0;
  font-size: 12px !important;
  font-family: "f2";
  color: red !important;
}

.err-input_log {
  // position: absolute;
  // right: 0;
  font-size: 12px !important;
  font-family: "f2";
  color: red !important;
}

.svg_log {
  width: 18px !important;
  color: red !important;
}

.err-input_new {
  position: absolute;
  font-size: 12px !important;
  font-family: "f2";
  color: red !important;
}

// POPUP
.alert-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 200 !important;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: 30px;
    // width: 50%;
    min-height: 30%;
    background-color: #fff;
    border-radius: 20px;
  }

  .customPopupDesign {
    width: 400px;
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: grid;
  }
}

//home banner slide
.carousel-control-next {
  display: none !important;
}

.carousel-control-prev {
  display: none !important;
}

.carousel-control-next:hover {
  display: none;
}

.carousel-control-prev {
  display: none;
}

.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  z-index: 2;
}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slide {
  .box:hover {
    transform: translateZ(20px);
  }
}

.slide-track:hover {
  animation-play-state: paused;
}

.slider {
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 9));
    }
  }

  overflow: hidden;
  position: relative;
  display: grid;
}

.slide-track {
  display: flex;
  width: calc(350px * 18);
  animation: scroll 40s linear infinite;
}

.active {
  // color: lime;
  font-weight: bold;
}

.Apps {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  display: flex;
}

.boxs {
  text-align: center;
  width: 500px;
  height: 300px;
  border: 1px solid black;
}

.modal-popup_2 {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    // align-items: center;
    // justify-content: center;
  }
}

.table-design-new {
  th {
    font-family: "f4" !important;
    font-size: 16px;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    padding: 12px;
  }
}

.delete-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 200 !important;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: 30px;
    // width: 50%;
    min-height: 30%;
    background-color: #fff;
    border-radius: 20px;
  }
}

.viewBoxDes {
  box-shadow: -5px -5px 15px 0px #fff, 5px 5px 15px 0px rgba(174, 174, 192, 0.4);
}

.viewDes {
  width: 20px;
  height: 16px;
}

.pointerView {
  cursor: pointer;
}

.border_click_cust {
  border: 2px $primary solid;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  color: $primary;
}

.show_mod_model {
  border-radius: 10px;
  background-color: transparent !important;
}

.product-img-table {
  width: 75% !important;
  overflow: scroll !important;

  th {
    font-family: "f4";
  }
}

.img-box-tee_new {
  width: 25% !important;

  img {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.accepted_btn_prime {
  background-color: #0782c5;
}

.leftBannerCom {
  margin-bottom: 30px;

  .tableHeaderStyle {
    border-bottom: 1px solid #e1ecf2 !important;
    border-top: 1px solid #e1ecf2 !important;
    border: 1px solid transparent;
    padding: 16px 8px;
    text-align: center;

    &:first-child {
      border-left: 1px solid #e1ecf2 !important;
    }

    &:last-child {
      border-right: 1px solid #e1ecf2 !important;
    }
  }

  .tableCellStyle {
    padding: 10px;
    min-width: 100px;
  }

  tr {
    border-bottom: 1px solid #e1ecf2 !important;
    border-top: 1px solid #e1ecf2 !important;
    border: 1px solid transparent;
    padding: 8px;
    text-align: center;
    justify-content: center;

    &:first-child {
      border-left: 1px solid #e1ecf2 !important;
    }

    &:last-child {
      border-right: 1px solid #e1ecf2 !important;
    }
  }
}

.get-start-view {
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
  font-family: "f2";
  background: #07679c;
}

.productCarousel {
  .item {
    transition: transform 0.3s ease;
  }

  .item:hover {
    transform: scale(1.05);
  }

  .detail-section {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
  }
}

.owl_arrow {
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel button.owl-dot.owl-nav {
    position: absolute;
    left: -25px;
    top: 50%;
    background-color: var(--base-color) !important;
    display: block;
    padding: 0 0.3em !important;
    font-size: 1em;
    margin: 0;
    cursor: pointer;
    color: #000;
    transform: translate(-50%, -50%);
  }

  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot.owl-nav {
    position: absolute;
    right: -42px;
    top: 50%;
    background-color: var(--base-color) !important;
    display: block;
    padding: 0 0.3em !important;
    font-size: 1em;
    margin: 0;
    cursor: pointer;
    color: #000;
    transform: translate(-50%, -50%);
  }

  .deals .owl-carousel .item img {
    width: 80px;
    margin: 0 auto;
    text-align: center;
  }

  .owl-theme .owl-nav [class*="owl-"]:hover {
    background-color: #fff;
  }

  .deals .owl-carousel .item figure {
    margin: 0;
  }

  .deals .owl-carousel .item figure a {
    display: block;
    text-align: center;
    max-width: 100%;
    transition: all 0.3s;
    padding: 3em;
    text-decoration: none;
    color: #565a5c;
  }

  .deals .owl-carousel .item figure a:hover {
    box-shadow: 0 11px 13.35px 1.65px rgba(148, 148, 148, 0.23);
    transform: scale(1.1);
  }
}
