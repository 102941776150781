//width-start

.w-5 {
  width: 5%;
}
.w-6 {
  width: 6%;
}
.w-7 {
  width: 7%;
}
.w-8 {
  width: 8%;
}
.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30% !important;
}

.w-32 {
  width: 32%;
}
.w-33 {
  width: 33%;
}
.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
  
}

.w-95 {
  width: 95%;
}

.w-96 {
  width: 96%;
}

.w-97 {
  width: 97%;
}

.w-98 {
  width: 98%;
}

.w-99 {
  width: 99%;
}
.wi-100 {
  width: 100%;
}
//width-end
//
//
//
//
@media (max-width: 576px) {
  .w-xs-10 {
    width: 10%;
  }

  .w-xs-15 {
    width: 15%;
  }
  .w-xs-20 {
    width: 20%;
  }

  .w-xs-25 {
    width: 25%;
  }

  .w-xs-30 {
    width: 30%;
  }

  .w-xs-32 {
    width: 32%;
  }
  .w-xs-33 {
    width: 33%;
  }

  .w-xs-35 {
    width: 35%;
  }

  .w-xs-40 {
    width: 40%;
  }

  .w-xs-45 {
    width: 45%;
  }
  .w-xs-47 {
    width: 47%;
  }
  .w-xs-48 {
    width: 48%;
  }
  .w-xs-49 {
    width: 49%;
  }
  .w-xs-50 {
    width: 50%;
  }
  .w-xs-55 {
    width: 55%;
  }
  .w-xs-60 {
    width: 60%;
  }
  .w-xs-65 {
    width: 65%;
  }
  .w-xs-70 {
    width: 70%;
  }
  .w-xs-75 {
    width: 75%;
  }
  .w-xs-80 {
    width: 80%;
  }
  .w-xs-85 {
    width: 85%;
  }
  .w-xs-90 {
    width: 90%;
  }
  .w-xs-95 {
    width: 95%;
  }
  .w-xs-96 {
    width: 96%;
  }
  .w-xs-97 {
    width: 97%;
  }
  .w-xs-98 {
    width: 98%;
  }
  .w-xs-99 {
    width: 99%;
  }
  .w-xs-100 {
    width: 100%;
  }
}
@media (min-width: 577px) {
  .w-sm-10 {
    width: 10%;
  }

  .w-sm-15 {
    width: 15%;
  }
  .w-sm-20 {
    width: 20%;
  }

  .w-sm-25 {
    width: 25%;
  }

  .w-sm-30 {
    width: 30%;
  }

  .w-sm-32 {
    width: 32%;
  }
  .w-sm-33 {
    width: 33%;
  }

  .w-sm-35 {
    width: 35%;
  }

  .w-sm-40 {
    width: 40%;
  }

  .w-sm-45 {
    width: 45%;
  }
  .w-sm-47 {
    width: 47%;
  }
  .w-sm-48 {
    width: 48%;
  }
  .w-sm-49 {
    width: 49%;
  }
  .w-sm-50 {
    width: 50%;
  }
  .w-sm-55 {
    width: 55%;
  }
  .w-sm-60 {
    width: 60%;
  }
  .w-sm-65 {
    width: 65%;
  }
  .w-sm-70 {
    width: 70%;
  }
  .w-sm-75 {
    width: 75%;
  }
  .w-sm-80 {
    width: 80%;
  }
  .w-sm-85 {
    width: 85%;
  }
  .w-sm-90 {
    width: 90%;
  }
  .w-sm-95 {
    width: 95%;
  }
  .w-sm-100 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .w-md-10 {
    width: 10%;
  }

  .w-md-15 {
    width: 15%;
  }
  .w-md-20 {
    width: 20%;
  }

  .w-md-25 {
    width: 25%;
  }

  .w-md-30 {
    width: 30%;
  }

  .w-md-32 {
    width: 32%;
  }
  .w-md-33 {
    width: 33%;
  }

  .w-md-35 {
    width: 35%;
  }
  .w-md-40 {
    width: 40%;
  }
  .w-md-45 {
    width: 45%;
  }
  .w-md-46 {
    width: 46%;
  }
  .w-md-47 {
    width: 47%;
  }
  .w-md-48 {
    width: 48%;
  }
  .w-md-49 {
    width: 49%;
  }
  .w-md-50 {
    width: 50%;
  }
  .w-md-55 {
    width: 55%;
  }
  .w-md-60 {
    width: 60%;
  }
  .w-md-65 {
    width: 65%;
  }
  .w-md-70 {
    width: 70%;
  }
  .w-md-75 {
    width: 75%;
  }
  .w-md-80 {
    width: 80%;
  }
  .w-md-85 {
    width: 85%;
  }
  .w-md-90 {
    width: 90%;
  }
  .w-md-95 {
    width: 95%;
  }
  .w-md-100 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .w-lg-10 {
    width: 10%;
  }

  .w-lg-15 {
    width: 15%;
  }
  .w-lg-20 {
    width: 20%;
  }

  .w-lg-25 {
    width: 25%;
  }

  .w-lg-30 {
    width: 30%;
  }

  .w-lg-32 {
    width: 32%;
  }
  .w-lg-33 {
    width: 33%;
  }
  .w-lg-35 {
    width: 35%;
  }
  .w-lg-40 {
    width: 40%;
  }
  .w-lg-45 {
    width: 45%;
  }
  .w-lg-46 {
    width: 46%;
  }
  .w-lg-47 {
    width: 47%;
  }
  .w-lg-48 {
    width: 48%;
  }
  .w-lg-49 {
    width: 49%;
  }
  .w-lg-50 {
    width: 50%;
  }
  .w-lg-55 {
    width: 55%;
  }
  .w-lg-60 {
    width: 60%;
  }
  .w-lg-65 {
    width: 65%;
  }
  .w-lg-70 {
    width: 70%;
  }
  .w-lg-75 {
    width: 75%;
  }
  .w-lg-80 {
    width: 80%;
  }
  .w-lg-85 {
    width: 85%;
  }
  .w-lg-90 {
    width: 90%;
  }
  .w-lg-95 {
    width: 95%;
  }
  .w-lg-100 {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .w-xl-10 {
    width: 10%;
  }

  .w-xl-15 {
    width: 15%;
  }
  .w-xl-20 {
    width: 20%;
  }

  .w-xl-25 {
    width: 25%;
  }

  .w-xl-30 {
    width: 30%;
  }

  .w-xl-32 {
    width: 32%;
  }
  .w-xl-33 {
    width: 33%;
  }
  .w-xl-35 {
    width: 35%;
  }
  .w-xl-40 {
    width: 40%;
  }
  .w-xl-45 {
    width: 45%;
  }
  .w-xl-46 {
    width: 46%;
  }
  .w-xl-47 {
    width: 47%;
  }
  .w-xl-48 {
    width: 48%;
  }
  .w-xl-49 {
    width: 49%;
  }
  .w-xl-50 {
    width: 50%;
  }
  .w-xl-55 {
    width: 55%;
  }
  .w-xl-60 {
    width: 60%;
  }
  .w-xl-65 {
    width: 65%;
  }
  .w-xl-70 {
    width: 70%;
  }
  .w-xl-75 {
    width: 75%;
  }
  .w-xl-70 {
    width: 70%;
  }
  .w-xl-80 {
    width: 80%;
  }
  .w-xl-85 {
    width: 85%;
  }
  .w-xl-90 {
    width: 90%;
  }
  .w-xl-95 {
    width: 95%;
  }
  .w-xl-100 {
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .w-xxl-10 {
    width: 10%;
  }

  .w-xxl-15 {
    width: 15%;
  }
  .w-xxl-20 {
    width: 20%;
  }

  .w-xxl-25 {
    width: 25%;
  }

  .w-xxl-30 {
    width: 30%;
  }

  .w-xxl-32 {
    width: 32%;
  }
  .w-xxl-33 {
    width: 33%;
  }
  .w-xxl-35 {
    width: 35%;
  }
  .w-xxl-40 {
    width: 40%;
  }
  .w-xxl-45 {
    width: 45%;
  }
  .w-xxl-46 {
    width: 46%;
  }
  .w-xxl-47 {
    width: 47%;
  }
  .w-xxl-48 {
    width: 48%;
  }
  .w-xxl-49 {
    width: 49%;
  }
  .w-xxl-50 {
    width: 50%;
  }
  .w-xxl-55 {
    width: 55%;
  }
  .w-xxl-60 {
    width: 60%;
  }
  .w-xxl-65 {
    width: 65%;
  }
  .w-xxl-70 {
    width: 70%;
  }
  .w-xxl-75 {
    width: 75%;
  }
  .w-xxl-70 {
    width: 70%;
  }
  .w-xxl-80 {
    width: 80%;
  }
  .w-xxl-85 {
    width: 85%;
  }
  .w-xxl-90 {
    width: 90%;
  }
  .w-xxl-95 {
    width: 95%;
  }
  .w-xxl-100 {
    width: 100%;
  }
}
