// $primary: #07679c;
$primary: #07679c;
$primary1: #839dd1;
$sec: #eb5160;
$sec1: #b60011;
$lt-primary: #f4f8ff;
$lt-blue: #f1f7ff;
$Blue: #1850a8;
$Blue1: #0082ca;
$lt-blue: #e9f0ff;
$lt-blue1: #cde2fe;
$lt-blue2: #f4f8ff;
$lt-blue3: #ecfaff;
$gray: #787b7f;
$gray1: #454545;
$gray2: #353535;
$gray3: #e5e5e5;
$gray4: #cbcbcb;
$white: #fff;
$black: #000;
$orange: #ff9a34;
$lt-orange: #fff3e0;
$red: #f82a2a;
$pink: #fb457d;
$pink1: #d9446f;
$greens: #009688;
$green1: #00d53c;
$dark-green: #01af32;
$dark-blue: #14213d;

$inr: ₹;

.black {
  color: $black !important;
}

.dark-blue {
  color: #14213d;
}

.d-blue {
  color: #0b2154;
}

.bg-btn-blue {
  background-color: #07679c !important;
}

.btn-blue {
  color: #07679c !important;
}

.err {
  // color: #ff0000;
  color: #df2a2a;
}

.primary {
  color: $primary !important;
}

.primary1 {
  color: $primary1 !important;
}

.sec {
  color: $sec !important;
}

.sec1 {
  color: $sec1;
}

.orange {
  color: $orange;
}

.lt-orange {
  color: $lt-orange;
}

.blue1 {
  color: $Blue1 !important;
}

.blue {
  color: #07679c !important;
}

.blue2 {
  color: #1850a8 !important;
}

.pinks {
  color: $pink !important;
}

.pink1 {
  color: $pink1 !important;
}

.dark-green {
  color: $dark-green !important;
}

.greens {
  color: $greens !important;
}

.green1 {
  color: $green1 !important;
}

.lt-Blue {
  color: $lt-blue;
}

.lt-blue1 {
  color: $lt-blue1;
}

.lt-blue2 {
  color: $lt-blue2;
}

.white {
  color: $white !important;
}

.gray {
  color: $gray;
}

.gray1 {
  color: $gray1;
}

.gray3 {
  color: $gray3;
}

.gray4 {
  color: $gray4;
}

.font-blue2 {
  color: #0b81acf5 !important;
}

.bg-primary2 {
  background-color: #9dc0d12b !important;
}

.bg-primar {
  background-color: $primary !important;
}

.bg-lt-primary {
  background-color: $lt-primary !important;
}

.bg-primary1 {
  background-color: $primary1 !important;
}

.bg-sec {
  background-color: $sec !important;
}

.bg-sec1 {
  background-color: $sec1 !important;
}

.bg-gradient {
  background: linear-gradient($primary, $primary1) !important;
}

.bg-bg-dark {
  background-color: $black !important;
}

.bg-darkBlue {
  background-color: $Blue !important;
}

.bg-blue1 {
  background-color: $Blue1 !important;
}

.bg-pinks {
  background-color: $pink !important;
}

.bg-red {
  background-color: $red !important;
}

.bg-pink1 {
  background-color: $pink1 !important;
}

.bg-greens {
  background-color: $greens !important;
}

.bg-green1 {
  background-color: $green1 !important;
}

.bg-lt-blue {
  background-color: $lt-blue !important;
}

.bg-lt-blue1 {
  background-color: $lt-blue1 !important;
}

.bg-lt-blue2 {
  background-color: $lt-blue2 !important;
}

.bg-lt-blue3 {
  background-color: $lt-blue3 !important;
}

.bg-gray {
  background-color: $gray !important;
}

.bg-orange {
  background-color: $orange;
}

.bg-lt-orange {
  background-color: $lt-orange;
}

.bg-gray1 {
  background-color: $gray1;
}

.bg-gray2 {
  background-color: $gray2 !important;
}

.bg-gray3 {
  background-color: $gray3 !important;
}

.bg-gray4 {
  background-color: $gray4 !important;
}

.bg-white {
  background-color: $white;
}

.border-primar {
  border: 2px solid $primary;
}

//

$pending: #f0bc10;
$lt-pending: #fdf4cd;
$design: #f4a42b;
$lt-design: #f9e5cc;
$production: #6650a5;
$lt-production: #dad2e9;
$ready: #0f4f8d;
$lt-ready: #d0e3f4;
// $approved: #67a84a;
$approved: #40bb53;
$lt-approved: #d8ead2;
$danger: red;

.pending {
  color: $pending;
}

.danger {
  color: $danger;
}

.lt-pending {
  color: $lt-pending;
}

.design {
  color: $design;
}

.lt-design {
  color: $lt-design;
}

.ready {
  color: $production;
}

.lt-ready {
  color: $lt-production;
}

.ready {
  color: $ready;
}

.lt-ready {
  color: $lt-ready;
}

.approved {
  color: $approved;
}

.lt-approved {
  color: $lt-approved;
}

.bg-pending {
  background-color: $pending;
}

.bg-processs {
  background-color: #ffa500;
}

.bg-ready_pro {
  background-color: #0782c5;
}

.bg-approved_pro {
  background-color: #40bb53;
}

.bg-rejeted {
  background-color: #ff0000;
}

.bg-lt-pending {
  background-color: $lt-pending;
}

.bg-design {
  background-color: $design;
}

.bg-lt-design {
  background-color: $lt-design;
}

.bg-ready {
  background-color: $production;
}

.bg-lt-ready {
  background-color: $lt-production;
}

.bg-ready {
  background-color: $ready;
}

.bg-lt-ready {
  background-color: $lt-ready;
}

.bg-approved {
  background-color: $approved;
}

.lt-approved {
  color: $lt-approved;
}

.text-Blue {
  color: $Blue;
}